import { palette } from '../../theme/colors';
import { Backdrop } from '../Backdrop/Backdrop';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../ActionButtons/ActionButtons.style';
import { Group } from '../../containers/GroupsList/groupsSlice/types';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import SCheckbox from '../FilterItem/SBoxButton';
import { ResetFiltersButton } from '../Button/ResetFiltersButton';
import {
  SFilterCheckboxItem,
  SFlexDiv,
  SFilter,
  SHeader,
  SLabelContainer,
  SLabel,
  ListContainer,
  SSearchBarBase,
} from './ChatListFilter.styles';

interface MessageFilterProps<T> {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onFilter: (selectedGroupsList: number[]) => void;
  label: string;
  title: string;
  searchbarTitle: string;
  selected: number[];
  groupsList: T[];
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
}

export const ChatListFilter = <T extends Pick<Group, 'name' | 'imageFileName' | 'id'>>(
  props: MessageFilterProps<T>
) => {
  const {
    isOpen,
    setIsOpen,
    onFilter,
    groupsList,
    title,
    searchbarTitle,
    selected,
    setSelected,
    label,
  } = props;

  const layout = useLayoutContext();

  const [searchText, setSearchText] = useState('');
  const [groupsToShow, setGroupsToShow] = useState<T[]>(groupsList);
  const [selectedGroups, setSelectedGroups] = useState<number[]>(selected);
  const allSelected = useMemo(() => {
    return selectedGroups.length === groupsList.length;
  }, [selectedGroups, groupsList]);

  const selectAll = () => {
    if (selectedGroups.length === groupsList.length) {
      setSelectedGroups([]);
      return;
    }
    setSelectedGroups(groupsList.map(group => group.id));
  };

  useEffect(() => {
    if (isOpen) {
      setSearchText('');
      setSelectedGroups(selected);
    }

    layout.setTabsState(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    const allGroupsIds = groupsList.map(group => group.id);
    setSelected(allGroupsIds);
    setSelectedGroups(allGroupsIds);
  }, []);

  useEffect(() => {
    if (groupsToShow !== groupsList) {
      setGroupsToShow(groupsList);
    }
  }, [groupsList]);

  const handleSearch = (search: string) => {
    setSearchText(search);
  };

  const handleReset = () => {
    setSearchText('');
    onFilter(groupsList.map(group => group.id));
  };

  const handleSelect = (groupId: number) => {
    const newState = [...selectedGroups];
    const isInArray = newState.includes(groupId);
    if (isInArray) {
      newState.splice(newState.indexOf(groupId), 1);
    } else {
      newState.push(groupId);
    }
    setSelectedGroups(newState);
  };

  const handleFilter = () => {
    onFilter(selectedGroups);
  };

  const includesText = (mainText: string) => {
    return mainText.toLowerCase().includes(searchText.toLowerCase());
  };
  const searchedGroups = searchText
    ? groupsToShow?.filter(e => includesText(e.name))
    : groupsToShow;

  const { t } = useTranslation();

  if (!isOpen) return <></>;

  return (
    <>
      <SFilter role="chatListFilter">
        <SHeader role="header">
          <rect width="400" height="100" fill={palette.silver} />
        </SHeader>
        <SSearchBarBase
          placeholderTx="documents_search"
          placeholder="Search..."
          fallback={handleSearch}
          label={t(`${searchbarTitle}`)}
          value={searchText}
        />
        <ListContainer role="groupsList">
          {!!groupsList?.length && (
            <SLabelContainer onClick={selectAll} role="titleContainer">
              <SLabel role="title">{t(`${title}`)}</SLabel>
              <SCheckbox isChecked={allSelected} />
            </SLabelContainer>
          )}
          <div role="searchedGroupsContainer">
            {searchedGroups.map((group, key) => (
              <div key={key} role="searchedGroup">
                <ul role="groupBody">
                  <SFilterCheckboxItem
                    checked={selectedGroups.includes(group.id)}
                    name={group.name}
                    setSelected={() => {
                      handleSelect(group.id);
                    }}
                    key={key}
                    id={group.id}
                    type={'box'}
                    hasImage
                    image={group.imageFileName}
                    isGroupImg
                    searchTerm={searchText}
                  />
                </ul>
              </div>
            ))}
          </div>
        </ListContainer>
        <SFlexDiv />
        <ResetFiltersButton
          onClick={handleReset}
          isDisplayed={!allSelected}
          marginBottom="0.8125rem"
        />
        <ActionButton type="button" tx={label} key={label} onClick={handleFilter} />
      </SFilter>
      <Backdrop setModal={setIsOpen} />
    </>
  );
};
