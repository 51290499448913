import { EmergencyType } from '../../containers/Alarm/AlarmSlice/types';

export const getAlarmName = (emergencyType: EmergencyType, currentLanguage: string) => {
  if (!emergencyType?.Names?.length) {
    return '';
  }

  const translation = emergencyType.Names?.find(item => item.Language === currentLanguage);
  return translation?.Name ?? emergencyType.Names[0].Name;
};
