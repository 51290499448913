import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Arrow from '../../assets/imgs/chats/arrow.svg';
import Pencil from '../../assets/imgs/chats/edit-yellow.svg';
import { SimpleText } from '../../components/Chat/ChatListItem.styles';
import { CreateChatFilter } from '../../components/Chat/CreateChatFilter';
import { FilterSection } from '../../components/SearchFilterBar/SearchFilterBar';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  createMessageIsLoading,
  getSelectedGroupType,
  getSelectedGroups,
  getSelectedUsers,
  selectActiveTab,
  setActiveTab,
  setIsLoading,
  setSelectedGroups,
  setSelectedUsers,
} from './createMessageSlice';
import {
  deleteGroupAction,
  deleteUserAction,
  sendAMessage,
} from './createMessageSlice/actionCreators';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { saveDocumentToServer } from '../../apis/mediaAPI';
import ChatBox from '../../components/Chat/ChatBox';
import Loader from '../../components/Loader/Loader';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { ActiveTab, GroupType } from '../../utils/enums';
import { CreateMessageModel } from '../Chat/models';
import { GroupMember } from '../GroupDetail/groupDetailSlice/types';
import { selectUser } from '../Login/LoginSlice';
import {
  GroupContainer,
  SClickableItem,
  SIcon,
  SInput,
  SItem,
  SLine,
  SPage,
} from './CreateMessageSummary.styles';
import MessageTypeFilter from './MessageTypeFilter';
import { SelectedGroup } from './SelectGroupsList';
import { SelectListUser } from './createMessageSlice/types';
import {
  getSelectedGroupsAndSelectedUsersAfterFilter,
  getSelectedUsersText,
  isAddGroupHidden,
} from './helpers';
import { RecipientsSummaryList } from '../../components/RecipientsSummaryList/RecipientsSummaryList';
import { RecipientType } from '../RecipientGroups/RecipientGroups';

export enum GroupsToShow {
  All = 'labelAll',
  MemberOfGroup = 'messages_groups_where_member',
  NotMemberOfGroup = 'messages_groups_where_not_member',
}

enum SoundType {
  Alarm = 'messages_sound_alarm',
  Regular = 'messages_sound_regular',
}

const SOUND_FILTERS: FilterSection = {
  title: 'messages_sound_selection_intro',
  type: 'radio',
  content: [
    {
      id: 0,
      name: SoundType.Regular,
      checked: true,
    },
    {
      id: 1,
      name: SoundType.Alarm,
      checked: false,
    },
  ],
};

const FILTER_SECTIONS: FilterSection = {
  title: 'messages_show_groups',
  type: 'radio',
  content: [
    {
      id: 0,
      name: 'messages_replyToAll',
      checked: true,
    },
    {
      id: 1,
      name: 'messages_replyToSender',
      checked: false,
    },
    {
      id: 2,
      name: 'messages_noReply',
      checked: false,
    },
  ],
};

const SMessageContainer = styled.div<{ $visible?: boolean }>`
  visibility: ${props => (props.$visible ? 'visible' : 'hidden')};
`;

const SSummaryBox = styled.div`
  padding: 0 1.25rem;
`;

interface CreateMessageSummaryProps {
  disableRecipientsChange?: boolean;
}

const CreateMessageSummary = (props: CreateMessageSummaryProps) => {
  const { disableRecipientsChange } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const confirm = useConfirmation();
  const { t } = useTranslation();

  const selectedUsers = useAppSelector(getSelectedUsers);
  const selectedGroups = useAppSelector(getSelectedGroups);
  const selectedGroupType = useAppSelector(getSelectedGroupType);
  const user = useAppSelector(selectUser);
  const activeTab = useAppSelector(selectActiveTab);
  const loading = useAppSelector(createMessageIsLoading);

  const [subjectText, setSubjectText] = useState('');
  const [isRecipientsFilterOpen, setIsRecipientsFilterOpen] = useState(false);
  const [tabBar, setTabBar] = useState(true);

  const [typeFilters, setTypeFilters] = useState(JSON.parse(JSON.stringify(FILTER_SECTIONS)));
  const [typeFilterOpen, setTypeFilterOpen] = useState(false);
  const [replyType, setReplyType] = useState(typeFilters.content[0].name);

  const [soundFilters, setSoundFilters] = useState(JSON.parse(JSON.stringify(SOUND_FILTERS)));
  const [soundFilterOpen, setSoundFilterOpen] = useState(false);
  const [soundType, setSoundType] = useState<string>(soundFilters.content[0].name);

  const subjectRef = useRef<HTMLInputElement>(null);

  const checkedMembers = useMemo(() => {
    return selectedUsers.filter((userItem: SelectListUser) => userItem.isSelected);
  }, [selectedUsers]);

  const emptyUsers = !selectedUsers.length;
  const emptyGroups = !selectedGroups.length;

  const isGroups = activeTab === ActiveTab.Groups;

  useEffect(() => {
    if (emptyUsers && !isGroups) {
      dispatch(setActiveTab(ActiveTab.Groups));
      navigate('/createMessage');
    }
  }, [navigate, selectedUsers, selectedGroups]);

  const getRecipientIds = (list: SelectListUser[]): number[] => {
    const filteredRecipientIds: number[] = list.reduce((acc: number[], item: SelectListUser) => {
      if (item.isSelected && item.id) acc.push(item.id);
      return acc;
    }, []);
    return filteredRecipientIds;
  };

  const getGroupsIDs = (list: SelectedGroup[]) => {
    const groupIds = list.reduce((acc: number[], groupItem: SelectedGroup) => {
      if (groupItem.id) acc.push(groupItem.id);
      return acc;
    }, []);
    return groupIds;
  };

  const getReplyTypeId = () => {
    switch (replyType) {
      case 'messages_replyToAll':
        return 0;
      case 'messages_replyToSender':
        return 1;
      default:
        return 2;
    }
  };

  const sendMessage = (
    text?: string,
    imageFileNames?: string[],
    documentFileNames?: string[],
    audioFileNames?: string[],
    locationId?: number
  ) => {
    const recipientsIDs: number[] = getRecipientIds(checkedMembers);
    const groupIds: number[] = selectedGroups.length ? getGroupsIDs(selectedGroups) : [];
    const messageModel: CreateMessageModel = {
      subject: subjectText === '' ? null : subjectText,
      senderId: user?.id,
      groupIds: groupIds,
      recipientIds: recipientsIDs.includes(user?.id || 0)
        ? recipientsIDs
        : [...recipientsIDs, user?.id || 0],
      documentFileNames: documentFileNames,
      audioFileNames: audioFileNames,
      photoFileNames: imageFileNames,
      text: text,
      locationId: locationId,
      replyType: getReplyTypeId(),
      type: soundType === SoundType.Alarm ? 11 : undefined,
    };

    if (recipientsIDs.length === 0) {
      confirm({
        title: 'sidebar_settings_testModalTitle',
        description: 'select_single_user_group_error',
        onSubmit: () => {
          dispatch(setIsLoading(false));
        },
      });
    } else {
      dispatch(sendAMessage(messageModel, navigate));
    }
  };

  const onPhotosSend = async (imagesList: string[], messageText: string) => {
    if (imagesList.length > 0) {
      const text = messageText && messageText.length > 0 ? messageText : undefined;
      sendMessage(text, imagesList);
    }
  };

  const onDocumentsSend = async (documentsList: File[], messageText: string) => {
    const documentsFilesNamesArray = [];

    for await (const document of documentsList) {
      const formData = new FormData();
      formData.append('document', document);
      const result = await saveDocumentToServer(formData);
      if (result) {
        documentsFilesNamesArray.push(result);
      }
    }

    if (documentsFilesNamesArray.length > 0) {
      const text = messageText && messageText.length > 0 ? messageText : undefined;

      sendMessage(text, [], documentsFilesNamesArray);
    }
  };

  const handleOnFilter = (selectedMembers: GroupMember[], extraUser?: SelectListUser[]) => {
    const { newSelectedUsers, newSelectedGroups } = getSelectedGroupsAndSelectedUsersAfterFilter(
      extraUser ?? [],
      selectedGroups,
      user
    );
    dispatch(setSelectedGroups(newSelectedGroups));
    dispatch(setSelectedUsers(newSelectedUsers));
    setIsRecipientsFilterOpen(false);
  };

  const withoutCurrentUserFilter = (
    list: (GroupMember | SelectListUser)[]
  ): (GroupMember | SelectListUser)[] => {
    return list.filter(item =>
      'userName' in item ? item.userID !== user?.id : item.id !== user?.id
    );
  };

  // #region Render fields TODO: Move to own component
  const renderRecipients = () => {
    if (emptyGroups) return <></>;
    return (
      <>
        <SItem role="recipientsContainer">
          <SimpleText className="left" fontSize="12px" role="label">
            {t(`messages_to`)}
          </SimpleText>
          <SClickableItem
            onClick={() => setIsRecipientsFilterOpen(!isRecipientsFilterOpen)}
            role="value"
          >
            <SimpleText fontSize="14px" className="right" role="title">
              {getSelectedUsersText(checkedMembers, selectedUsers, user?.id)}
            </SimpleText>
            <SIcon src={Arrow} alt="" className="" role="arrowIcon" />
          </SClickableItem>
        </SItem>
        <SLine role="line" />
      </>
    );
  };

  const renderType = () => {
    if (
      emptyGroups ||
      selectedGroupType.includes(GroupType.Hidden) ||
      selectedGroupType.includes(GroupType.CoAlert)
    )
      return <></>;

    return (
      <>
        <SItem role="replyToContainer">
          <SimpleText className="left" fontSize="12px" role="label">
            {t(`messages_replyTo`)}
          </SimpleText>
          <SClickableItem
            id="clickableReplyType"
            onClick={() => setTypeFilterOpen(!typeFilterOpen)}
            role="value"
          >
            <SimpleText id="replyType" fontSize="14px" className="right" role="title">
              {t(replyType)}
            </SimpleText>
            <SIcon src={Arrow} alt="" role="arrowIcon" />
          </SClickableItem>
        </SItem>
        <SLine role="line" />

        <MessageTypeFilter
          isOpen={typeFilterOpen}
          list={typeFilters}
          setIsOpen={() => setTypeFilterOpen(!typeFilterOpen)}
          setCheckedState={setTypeFilters}
          setType={setReplyType}
        />
      </>
    );
  };

  const renderSound = () => {
    if (!selectedGroupType.includes(GroupType.CrossOrg)) return <></>;

    return (
      <>
        <SItem role="soundContainer">
          <SimpleText className="left" fontSize="12px" role="label">
            {t(`messages_sound`)}
          </SimpleText>
          <SClickableItem onClick={() => setSoundFilterOpen(!soundFilterOpen)} role="value">
            <SimpleText fontSize="14px" className="right" role="title">
              {t(soundType)}
            </SimpleText>
            <SIcon src={Arrow} alt="" role="arrowIcon" />
          </SClickableItem>
        </SItem>
        <SLine role="line" />

        <MessageTypeFilter
          isOpen={soundFilterOpen}
          list={soundFilters}
          setIsOpen={() => setSoundFilterOpen(!soundFilterOpen)}
          setCheckedState={setSoundFilters}
          setType={setSoundType}
        />
      </>
    );
  };

  const renderSubject = () => {
    if (selectedGroupType.includes(GroupType.CoAlert)) return <></>;
    const highlightText = () => {
      if (subjectRef.current) subjectRef.current.focus();
    };

    return (
      <>
        <SItem className="input" role="subjectContainer">
          <SimpleText className="left" fontSize="12px" role="label">
            {t(`messages_subject_add`)}
          </SimpleText>
          <SInput
            ref={subjectRef}
            onChange={e => setSubjectText(e.target.value)}
            value={subjectText}
            type="text"
            placeholder={t(`messages_subject_add_placeholder`)}
            role="subjectInput"
          />
          <SIcon src={Pencil} alt="" className="pencil" onClick={highlightText} role="editIcon" />
        </SItem>
        <SLine role="line" />
      </>
    );
  };
  // #endregion

  const handleRemoveClick = (id: number) => {
    if (emptyGroups) {
      dispatch(deleteUserAction(id));
    } else {
      dispatch(deleteGroupAction(id));
    }
  };

  return (
    <SPage>
      {!loading && (
        <SSummaryBox role="summaryBox">
          <GroupContainer role="groupsContainer">
            <RecipientsSummaryList
              data={emptyGroups ? selectedUsers : selectedGroups}
              handleAddClick={
                !isAddGroupHidden(selectedGroups, disableRecipientsChange)
                  ? () => navigate('/createMessage')
                  : undefined
              }
              handleRemoveClick={disableRecipientsChange ? undefined : handleRemoveClick}
              recipientsType={emptyGroups ? RecipientType.Users : RecipientType.Groups}
              nameProperty={emptyGroups ? 'displayName' : 'name'}
            />
          </GroupContainer>
          <CreateChatFilter
            searchbarTitle="messages_selectRecepients"
            title={'messages_allMembers'}
            label={'messages_filter'}
            isOpen={isRecipientsFilterOpen}
            items={[]}
            /// refactoring needed
            extraMembers={withoutCurrentUserFilter(selectedUsers) as SelectListUser[]}
            setIsOpen={() => setIsRecipientsFilterOpen(!isRecipientsFilterOpen)}
            onFilter={handleOnFilter}
            isCoAlert={selectedGroupType.includes(GroupType.CoAlert)}
            allowEmptyFilter
          />
          <SLine role="line" />
          {renderRecipients()}
          {renderType()}
          {renderSound()}
          {renderSubject()}
        </SSummaryBox>
      )}
      {loading && <Loader />}
      <SMessageContainer $visible={!loading} role="createMessageContainer">
        <ChatBox
          tabBar={tabBar}
          setTabBar={setTabBar}
          onTextSend={sendMessage}
          onPhotosSend={onPhotosSend}
          onDocumentsSend={onDocumentsSend}
          onLocationSend={(locationId, text) => sendMessage(text, [], [], [], locationId)}
        />
      </SMessageContainer>
    </SPage>
  );
};

export default CreateMessageSummary;
