import { t } from 'i18next';
import { selectUser } from '../../containers/Login/LoginSlice';
import { useAppSelector } from '../../hooks';
import { Container, SInfoRow, SInfoText } from './SidebarAvailabilityButton.style';
import ArrowRightImg from '../../assets/icons/chevron-right.svg';
import { User } from '../../apis/authApi/types';
import { getDateTextByLanguage } from './helpers';
import { SCircle } from '../UserProfileAvailabilityButton/UserProfileAvailabilityButton.style';

const SidebarAvailabilityButton = () => {
  const authUser = useAppSelector(selectUser);
  const { available = true, unavailableDateTo = null } = authUser as User;

  return (
    <Container to="/sidebar/enable-not-available-mode">
      <SInfoRow>
        <SCircle isAvailable={available} />
        <SInfoText>
          <p className="status">{`${t('profile_status')}: ${t(
            available ? 'profile_available' : 'profile_not_available'
          )}`}</p>
          {!available && unavailableDateTo && (
            <p className="endDate">
              {t('profile_not_available_mode_enabled_description', {
                dateTime: getDateTextByLanguage(unavailableDateTo),
              })}
            </p>
          )}
        </SInfoText>
      </SInfoRow>
      <img height={20} className="editIcon" src={ArrowRightImg} alt="edit" role="editIcon" />
    </Container>
  );
};

export default SidebarAvailabilityButton;
