import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CountryCodeDropDown } from '../../components/CountryCodeDropDown/CountryCodeDropDown';
import Country from '../../components/CountryCodeDropDown/resources/country';
import { CountryCode, OnCountryCodeChangeParams } from '../../components/CountryCodeDropDown/types';
import { CountryCodeTextField } from '../../components/CountryCodeTextField/CountryCodeTextField';
import Loader from '../../components/Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import i18n, { ELanguages, EPhoneCodes } from '../../i18n';
import { translate } from '../../utils/translate';
import { selectUserById, selectUserByIdLoading } from '../Login/LoginSlice';
import { editSecondaryPhoneNumber, getCurrentUserById } from '../Login/LoginSlice/actionCreators';
import {
  SButton,
  SContainer,
  SStritchedSpace,
  SSubTitle,
  STitle,
} from './ChangeSecondaryNumber.styles';
import { PhoneNumberShorten, getSecondaryPhoneNumber, isNumbersOnly } from './helpers';

export const ChangeSecondaryNumber = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const user = useAppSelector(selectUserById);
  const isGettingDataLoading = useAppSelector(selectUserByIdLoading);

  const [countryCode, setCountryCode] = useState<CountryCode>(
    (EPhoneCodes[i18n.language] as CountryCode) || 'SE'
  );
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const [initialPhoneNumber, setInitialPhoneNumber] = useState<PhoneNumberShorten | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const getPhoneNumber = () => {
    const phoneData = getSecondaryPhoneNumber(user, i18n.language as ELanguages);
    setInitialPhoneNumber(phoneData);
    if (phoneData?.country) setCountryCode(phoneData.country);
    if (phoneData?.nationalNumber) setPhoneNumber(phoneData.nationalNumber);
  };

  // backend sends two pohne number seperated with , and a space
  useEffect(() => {
    if (!user) {
      dispatch(getCurrentUserById());
    }
    getPhoneNumber();
  }, [dispatch, user]);

  const onCountryChanged = (params: OnCountryCodeChangeParams) => {
    setCountryCode(params.countryCode);
  };
  const onNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = e.target;
    if (isNumbersOnly(value) || !value.length) {
      setPhoneNumber(value);
    }
  };

  const handleSave = async () => {
    const dialCode = Country.countryCodeToDialCode(countryCode);
    try {
      setIsLoading(true);
      await dispatch(editSecondaryPhoneNumber(dialCode, phoneNumber ?? ''));
      setIsLoading(false);
      navigation(-1);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const canShowSaveButton = () => {
    const isCountryChanged = countryCode !== initialPhoneNumber?.country;
    const isPhoneChanged = phoneNumber !== initialPhoneNumber?.nationalNumber;
    return isPhoneChanged || (isCountryChanged && !!phoneNumber?.trim()?.length);
  };

  if (isLoading || isGettingDataLoading) return <Loader />;

  return (
    <SContainer role="changePhoneContainer">
      <STitle role="title">{translate('profile_changeSecNumber')}</STitle>
      <SSubTitle role="subtitle">{translate('profile_phone_intro')}</SSubTitle>
      <CountryCodeTextField
        StartAdornment={
          !isGettingDataLoading && (
            <CountryCodeDropDown initiallValue={countryCode} onChange={onCountryChanged} />
          )
        }
        type="tel"
        name="number"
        placeHolderTx="profile_yourPhoneNumber"
        value={phoneNumber}
        onChange={onNumberChange}
        role="phoneInput"
      />
      <SStritchedSpace />
      {canShowSaveButton() && <SButton onClick={handleSave} tx="save" />}
    </SContainer>
  );
};
