/* eslint-disable @typescript-eslint/no-shadow */

import React, { useEffect, useState } from 'react';
import { getGroupById } from '../../../apis/groupsAPI';
import { Checklist } from '../../../containers/Checklists/checklistsSlice/types';
import { GroupDetail } from '../../../containers/GroupDetail/groupDetailSlice/types';
import { useAppSelector } from '../../../hooks';
import { useLayoutContext } from '../../../utils/customHooks/LayoutContext';
import { Backdrop } from '../../Backdrop/Backdrop';
import { SCancel } from '../../Options/Options.styles';
import { hasChecklistRole } from '../helpers';
import { selectUserRoles } from '../../../containers/Login/LoginSlice';
import { useParams } from 'react-router-dom';
import ChecklistDetailsButtons from './ChecklistDetailsButtons';
import { translate } from '../../../utils/translate';
import ChecklistInfo from './ChecklistInfo';
import { SChecklistSettingsContainer } from './ChecklistDetails.styles';

interface ChecklistDetailsProps {
  isOpen: boolean;
  setIsOpen: () => void;
  data: Checklist | null;
  setAddItemModalOpen?: () => void;
}

function ChecklistDetails(props: ChecklistDetailsProps) {
  const { isOpen, setIsOpen, data, setAddItemModalOpen } = props;

  const { id: idFromUrl } = useParams();
  const layout = useLayoutContext();

  const roles = useAppSelector(selectUserRoles);
  const insideChecklist = idFromUrl !== undefined;

  const [foundSharedGroups, setFoundSharedGroups] = useState<GroupDetail[]>([]);

  const getGroups = async () => {
    if (!data?.sharedGroups) return;
    const tempArr: GroupDetail[] = [];
    for await (const groupId of data?.sharedGroups) {
      try {
        if (!tempArr.find(group => group.id === groupId)) {
          const foundGroup = await getGroupById(groupId);
          tempArr.push(foundGroup);
        }
      } catch (error) {
        console.error('Error: Failed to find group');
      }
    }
    return tempArr;
  };

  useEffect(() => {
    let isMounted = true;
    getGroups().then(data => {
      if (isMounted && data) setFoundSharedGroups(data);
    });
    return () => {
      isMounted = false;
    };
  }, [data, isOpen]);

  useEffect(() => {
    layout.setTabsState(!isOpen);
    if (!isOpen) setFoundSharedGroups([]);
  }, [isOpen]);

  const canEdit = hasChecklistRole(roles);

  if (!data) return <></>;

  const { id, name, status } = data;

  if (!isOpen) return <></>;

  return (
    <>
      <SChecklistSettingsContainer role="checklistSettingsContainer">
        <ChecklistInfo data={data} foundSharedGroups={foundSharedGroups} />
        <ChecklistDetailsButtons
          canEdit={canEdit}
          status={status}
          insideChecklist={insideChecklist}
          id={id}
          foundSharedGroups={foundSharedGroups}
          setIsOpen={setIsOpen}
          name={name}
          setAddItemModalOpen={setAddItemModalOpen}
        />
        <SCancel onClick={setIsOpen} className="wrapper cancel" role="cancelButton">
          <p role="cancelText">{translate(`cancel`)}</p>
        </SCancel>
      </SChecklistSettingsContainer>
      <Backdrop setModal={setIsOpen} />
    </>
  );
}

export default ChecklistDetails;
