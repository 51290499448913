import { t } from 'i18next';
import isElectron from 'is-electron';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CloseWhite from '../../assets/imgs/alarms/CloseWhite.svg';
import YellowInfoCircle from '../../assets/imgs/alarms/YellowInfoCircle.svg';
import Loader from '../../components/Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { palette } from '../../theme/colors';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { convertSecondsToTime } from '../../utils/date';
import { ipcRenderOn, removeIpcListener } from '../../utils/electronHooks';
import {
  SDeactivateButton,
  SNavigateToChatButton,
  SNotifyInfoRow,
  SPage,
  STimeAddButton,
  STimeAddRow,
  STimeAddedBanner,
  STimerActionsContainer,
  STimerCircle,
  STimerContainer,
  STimerTitleText,
} from './LoneWorkerTimer.styles';
import { calculateSecondsBetweenTwoDateTimes } from './helpers';
import { getAlarmData, getIsLoading } from './loneWorkerTimerSlice';
import {
  deactivateAlarm,
  fetchAlarmData,
  fetchFinishedAlarmData,
  goToAlarmChat,
  setOffTimer,
  updateTimer,
} from './loneWorkerTimerSlice/actionCreator';

export const LoneWorkerTimer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirmation();
  const isLoading = useSelector(getIsLoading);
  const alarmData = useAppSelector(getAlarmData);
  const holdOffset = 3000;
  const [addedBannerText, setAddedBannerText] = useState<string | null>(null);
  const [millisecondsHold, setMillisecondsHold] = useState(0);
  const [isHolding, setIsHolding] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const holdPercentage = useMemo(() => (millisecondsHold / holdOffset) * 100, [millisecondsHold]);
  const passedPercentage = useMemo(() => {
    if (alarmData) {
      const needAddZAtEnd =
        alarmData.DispatchTime.toString().includes('Z') &&
        !alarmData.CreatedTime.toString().includes('Z');
      const alarmTotalSeconds = calculateSecondsBetweenTwoDateTimes(
        new Date(`${alarmData.CreatedTime}${needAddZAtEnd ? 'Z' : ''}`),
        new Date(alarmData.DispatchTime)
      );
      const percentage = ((alarmTotalSeconds - seconds) / alarmTotalSeconds) * 100;
      return Math.min(100, Math.max(0, percentage));
    } else {
      return 0;
    }
  }, [seconds]);

  function setTimer() {
    if (!alarmData) return;
    const secondsLeft = calculateSecondsBetweenTwoDateTimes(
      new Date(),
      new Date(alarmData.DispatchTime)
    );
    setSeconds(secondsLeft > 0 ? secondsLeft : 0);
  }

  const listenForReturnToForeground = () => {
    if (isElectron() && localStorage.getItem('isLoneWorkTimerActive')) {
      dispatch(fetchFinishedAlarmData(navigate));
    }
  };

  useEffect(() => {
    ipcRenderOn('on-app-foreground', () => listenForReturnToForeground());
    return () => {
      removeIpcListener('on-app-foreground', () => {});
    };
  }, []);
  useEffect(() => {
    if (+seconds.toFixed(0) > 0) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(+seconds.toFixed(0) - 1);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [seconds]);
  useEffect(() => {
    //used if the application will be launched in a browser.
    //on browser nav back click redirect to the dashboard
    if (!isElectron()) {
      window.addEventListener('popstate', () => {
        navigate('/dashboard');
      });
    }
    if (!alarmData) {
      dispatch(fetchAlarmData(navigate));
    } else {
      localStorage.setItem('isLoneWorkTimerActive', JSON.stringify(true));
      setTimer();
    }
  }, [alarmData]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;
    if (isHolding) {
      interval = setInterval(() => {
        setMillisecondsHold(val => {
          const newValue = val + 100;
          if (newValue >= holdOffset) {
            clearInterval(interval);
          }
          return newValue > 0 ? newValue : 0;
        });
      }, 100);
    } else if (!isHolding && millisecondsHold > 0) {
      clearInterval(interval);
      interval = setInterval(() => {
        setMillisecondsHold(val => {
          const newValue = val - 100;
          if (newValue <= 0) {
            clearInterval(interval);
          }
          return newValue > 0 ? newValue : 0;
        });
      }, 100);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isHolding]);

  useEffect(() => {
    if (millisecondsHold === holdOffset) {
      dispatch(setOffTimer(navigate));
    }
  }, [millisecondsHold]);

  useEffect(() => {
    if (addedBannerText) {
      setTimeout(() => {
        setAddedBannerText(null);
      }, 3000);
    }
  }, [addedBannerText]);

  const updateTime = async (newDispatchTime: Date) => {
    if (!alarmData) return;
    const updateTimerData = {
      deviceID: alarmData.DeviceId,
      dispatchTime: newDispatchTime,
      locationId: alarmData.LocationId,
    };
    dispatch(updateTimer(updateTimerData));
  };

  const addTime = async (secondsToAdd: number) => {
    if (!alarmData) return;
    const secondsInDay = 86399;
    const dispatchTime = new Date(alarmData.DispatchTime);
    const dispatchTimeSeconds = dispatchTime.getSeconds();
    dispatchTime.setSeconds(dispatchTimeSeconds + secondsToAdd);
    const secondsLeft = calculateSecondsBetweenTwoDateTimes(new Date(), new Date(dispatchTime));
    const minutesToAdd = secondsToAdd / 60;
    if (secondsLeft <= secondsInDay) {
      await updateTime(dispatchTime);
      setAddedBannerText(
        `${t('alarm_added')} ${minutesToAdd} ${t('alarm_minutes').toLocaleLowerCase()}`
      );
    } else {
      const secondsManaged = secondsLeft - secondsToAdd;
      const timeToAdd = secondsInDay - secondsManaged;
      dispatchTime.setSeconds(dispatchTimeSeconds - secondsToAdd + timeToAdd);
      await updateTime(dispatchTime);
      setAddedBannerText(
        `${t('alarm_added')} ${minutesToAdd} ${t('alarm_minutes').toLocaleLowerCase()}`
      );
      setTimeout(() => {
        confirm({
          title: 'warning',
          description: 'alarm_timer_over_24_hours_warning_description',
          onSubmit: () => {},
          confirmText: 'ok',
        });
      }, 500); // By wrapping it with timeout user at first will see how time is changed and only then modal will appear
    }
  };

  const cancelAlarm = async () => {
    localStorage.removeItem('isLoneWorkTimerActive');
    dispatch(deactivateAlarm(navigate, 'dashboard'));
  };

  const onDeactivateClick = () => {
    confirm({
      title: 'confirmation_required',
      description: 'alarm_timer__cancel_confirmation__description',
      onSubmit: () => cancelAlarm(),
      onCancel: () => {},
      confirmText: 'alarm_deactivate',
      cancelText: 'cancel',
    });
  };
  const goToChat = () => {
    localStorage.removeItem('isLoneWorkTimerActive');
    dispatch(goToAlarmChat(navigate));
  };
  const changeHoldingState = (newState: boolean) => {
    if (seconds > 0) {
      setIsHolding(newState);
    }
  };
  if (isLoading) {
    return (
      <SPage>
        <Loader />
      </SPage>
    );
  }
  return (
    <SPage>
      {addedBannerText && (
        <STimeAddedBanner role="timeAddedBanner">
          <p role="text">{addedBannerText}</p>
          <img onClick={() => setAddedBannerText(null)} src={CloseWhite} role="closeIcon" />
        </STimeAddedBanner>
      )}
      <STimerContainer role="timerContainer">
        <STimerTitleText role="timerTitleText">
          {seconds > 0 && t(isHolding ? 'alarm_timer_hold_desc_active' : 'alarm_timer_hold_desc')}
        </STimerTitleText>
        <STimerCircle
          onMouseDown={() => changeHoldingState(true)}
          onMouseUp={() => changeHoldingState(false)}
          onMouseLeave={() => changeHoldingState(false)}
          role="timerCircle"
        >
          {seconds > 0 && (
            <svg width="280" height="280" viewBox="0 0 280 280">
              <circle
                r="136"
                cx="140"
                cy="140"
                fill={palette.transparent}
                stroke={seconds < 60 ? palette.danger : palette.earthYellow}
                strokeLinecap="round"
                strokeWidth="6px"
                strokeDasharray={`${String(3.14 * 136 * 2)}px`}
                strokeDashoffset={`${String(3.14 * 136 * 2 * (passedPercentage / 100))}px`}
              ></circle>
              <circle
                r="134"
                cx="140"
                cy="140"
                fill={palette.transparent}
                stroke={millisecondsHold ? palette.danger : palette.transparent}
                strokeWidth="12px"
                strokeDasharray={`${String(3.14 * 134 * 2)}px`}
                strokeDashoffset={`${
                  Number(String(3.14 * 134 * 2)) * (1 + holdPercentage / 100)
                }px`}
              ></circle>
            </svg>
          )}
          <h1>{convertSecondsToTime(seconds)}</h1>
        </STimerCircle>
        {seconds > 0 ? (
          <>
            <SNotifyInfoRow role="notifyInfoRow">
              {alarmData?.oneMinuteWarning && (
                <>
                  <img src={YellowInfoCircle} role="icon" />
                  <p role="reminderText">{t('notification_reminder')}</p>
                </>
              )}
            </SNotifyInfoRow>
          </>
        ) : (
          <SNavigateToChatButton onClick={goToChat} tx="alarm_open_alarm_message" />
        )}
      </STimerContainer>
      {seconds > 0 && (
        <STimerActionsContainer isTransparent={isHolding} role="timerActionsContainer">
          <div className="content" role="timeAddRowContainer">
            <STimeAddRow role="timeAddRow">
              <STimeAddButton
                text={t('work_alone_alarm_add10min')}
                onClick={() => addTime(600)}
                role="add10MinutesButton"
              ></STimeAddButton>
              <STimeAddButton
                text={t('work_alone_alarm_add30min')}
                onClick={() => addTime(1800)}
                role="add30MinutesButton"
              ></STimeAddButton>
            </STimeAddRow>
            <SDeactivateButton
              color="red"
              text={t('alarm_recall')}
              onClick={onDeactivateClick}
              role="deactivateBtn"
            ></SDeactivateButton>
          </div>
        </STimerActionsContainer>
      )}
    </SPage>
  );
};
