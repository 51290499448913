import styled from 'styled-components';
import BigFloatButton from '../../components/BigFloatButton/BigFloatButton';
import { Page } from '../../components/Page/Page';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SBigFloatButton = styled(BigFloatButton)<{ $tasksEmpty?: boolean }>`
  background-color: ${palette.navyBlue};
  color: ${palette.honeyYellow};
  border: 1px solid ${palette.honeyYellow};
  bottom: ${props => (props.$tasksEmpty ? '6rem' : '10rem')};
`;

export const SPage = styled(Page)`
  padding: 1.25rem 0;
`;

export const SNewChecklist = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 0;
  height: 100%;
`;

export const SNewCheclistItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 9rem;

  min-height: 0;
  height: 100%;

  /* vertical scrolling + hide scroller bar  */
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SNewCheclistItem = styled.div<{ $isFirst?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${palette.queenBlue};
  background-color: ${palette.prussianBlue};
  padding: 1.25rem;
  margin-top: ${props => !props.$isFirst && toRem(10)};

  p {
    margin-left: 10px;
    font-family: 'Roboto-Regular';
    font-size: 16px;
    letter-spacing: 0.01em;
  }

  .dots {
    margin-left: auto;
    cursor: pointer;
  }

  .nameContainer {
    flex: 1;
    padding-left: 0.25rem;
    padding-right: 0.5rem;
  }
`;
