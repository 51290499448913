import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getImage } from '../../../apis/mediaAPI';
import PersonalAlarmImg from '../../../assets/imgs/alarms/siren-red.svg';
import PlaceholderMap from '../../../assets/imgs/chats/map-placeholder2.png';
import { ReactComponent as TriangleError } from '../../../assets/imgs/chats/triangle-error.svg';
import instructionsIcon from '../../../assets/imgs/exclamation-mark-circle.svg';
import { useLocation } from '../../../utils/customHooks/useLocation';
import { dateFormats, getDateFormatCustom } from '../../../utils/date';
import { MessageSubType, MessageType } from '../../../utils/enums';
import { getItem } from '../../../utils/storage';
import { translate } from '../../../utils/translate';
import HyperLinkText from '../../HyperlinkText/HyperLinkText';
import { MapMessageView, SMapMessageView } from '../../MapMessageView/MapMessageView';
import MessageContainer from '../../MessageContainer/MessageContainer';
import { PrewrittenTextLanguage } from '../../../containers/ChatsList/chatListSlice/types';
import { defaultLanguage } from '../../../i18n';
import {
  SMapWrapper,
  SAlarmText,
  SAlertContainer,
  SAlertMessageInfoContainer,
  SCustomTextWrapper,
  SNoMapContainer,
  SPrewrittenTextTitleText,
  SPrewrittenTextTitleWrapper,
  SPrewrittenTextWrapper,
  STime,
  SCustomTextWrapperTime,
} from './AlertMessage.styles';

interface AlertMessageProps {
  isSent: boolean;
  sentDateTime: string;
  message: any;
  senderName: string;
  senderImage: string | null;
  messageTo: string | null;
  toggleModal?: () => void;
  messageText?: string;
  onMapClick?: (longitude: number, latitude: number, address: string) => void;
  forwarded?: boolean;
  id?: number;
  didFetchPhoto?: () => void;
}

export const AlertMessage = (props: AlertMessageProps) => {
  const {
    message,
    isSent,
    sentDateTime,
    senderImage,
    senderName,
    messageTo,
    toggleModal,
    forwarded,
    onMapClick,
    id,
    didFetchPhoto,
  } = props;
  const { location, isLocationLoading } = useLocation(message.locationID);
  const [image, setImage] = useState('');
  const { t } = useTranslation();

  const isPersonalAlarm = message?.subType === MessageSubType.personalAlarm;

  const language = getItem('language');
  const getAlarmImage = async () => {
    if (message?.emergencyTypeIconFileName) {
      const img = await getImage({
        imageName: message?.emergencyTypeIconFileName,
        svg: true,
      });
      setImage(img);
    }
    if (isPersonalAlarm) setImage(PersonalAlarmImg);
  };

  useEffect(() => {
    getAlarmImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message?.id]);

  const messageType =
    isSent && !message.recalled
      ? MessageType.SentAlarmMessage
      : !isSent && !message.recalled
      ? MessageType.ReceivedAlarmMessage
      : isSent && message.recalled
      ? MessageType.SentAlarmRecalledMessage
      : MessageType.ReceivedRecalledAlarmMessage;

  const hasCustomText = message.text?.trim().length > 0;
  const hasInstructions = !!message?.emergencyTypePrewrittenTexts?.length;
  const hasAlarmInfo = hasCustomText || hasInstructions;

  const getDefaultLanguageInstructions = (
    emergencyTypePrewrittenTexts: PrewrittenTextLanguage[]
  ): string | undefined => {
    return emergencyTypePrewrittenTexts.find(
      (element: PrewrittenTextLanguage) => element.Language === defaultLanguage
    )?.Text;
  };

  const getInstructions = (): string => {
    if (!message?.emergencyTypePrewrittenTexts?.length) return '';

    const { emergencyTypePrewrittenTexts, emergencyTypePrewrittenText } = message;

    return (
      emergencyTypePrewrittenText ||
      getDefaultLanguageInstructions(emergencyTypePrewrittenTexts) ||
      emergencyTypePrewrittenTexts[0].Text
    );
  };

  const instructions = getInstructions();

  return (
    <>
      <MessageContainer
        messageType={messageType}
        senderImage={senderImage}
        senderName={senderName}
        messageTo={forwarded ? null : messageTo}
        toggleModal={toggleModal}
        alert={forwarded ? undefined : !message.recalled}
        forwarded={forwarded}
        id={id}
        hasAlarmInfo={hasAlarmInfo}
      >
        <SAlertContainer isSent={isSent} role="alertMessageInnerContainer">
          <SAlertMessageInfoContainer role="infoContainer">
            <div className="alertDetailsMessageContainer" role="detailsContainer">
              {image && (
                <div className="alertIconContainer" role="iconContainer">
                  <img width={24} src={image} alt="" role="icon" />
                </div>
              )}
              <div className="text-container" role="textContainer">
                <p className="emergencyType" role="emergencyTypeText">
                  {isPersonalAlarm ? t('alarm_personal_alarm') : message.emergencyTypeName}
                </p>
                {message.Organization && message.Organization.name && (
                  <p
                    className={forwarded ? 'forwardedAlarmOrganization' : 'organization'}
                    role="organizationText"
                  >
                    {translate(forwarded ? 'alarm_forwarded_sent_from' : 'sent_from')}{' '}
                    {message.Organization.name}
                  </p>
                )}
                {!isLocationLoading && location && location.name ? (
                  <p
                    className={forwarded ? 'forwardedAlarmLocation' : 'location'}
                    role="locationText"
                  >
                    {location.name}
                    {forwarded ? `, ${sentDateTime}` : ''}
                  </p>
                ) : (
                  <p className="location" role="locationText">
                    {forwarded
                      ? `, ${getDateFormatCustom(sentDateTime, dateFormats.monthNameDateTime)}`
                      : ''}
                  </p>
                )}
              </div>
            </div>
          </SAlertMessageInfoContainer>
        </SAlertContainer>
        <SMapWrapper>
          {!isLocationLoading && location ? (
            <MapMessageView
              didFetchPhoto={didFetchPhoto}
              longitude={Number(location?.longitude)}
              latitude={Number(location?.latitude)}
              isSameSender={false}
              isSent={isSent}
              gestureHandling="none"
              onClick={
                onMapClick &&
                (() =>
                  onMapClick(
                    Number(location?.longitude),
                    Number(location?.latitude),
                    location.name!
                  ))
              }
            />
          ) : (
            <SMapMessageView role="mapView">
              <SNoMapContainer role="noMapContainer">
                <div role="textContainer">
                  <TriangleError role="icon" />
                  <p role="noLocationText">{translate('messages_alarm_no_location')}</p>
                </div>
                <img src={PlaceholderMap} alt="" role="mapPlaceholder" />
              </SNoMapContainer>
            </SMapMessageView>
          )}
          {!hasCustomText && <STime>{sentDateTime}</STime>}
        </SMapWrapper>
        {!!instructions && (
          <SPrewrittenTextWrapper role="prewrittenTextContainer">
            <SPrewrittenTextTitleWrapper role="titleContainer">
              <img src={instructionsIcon} alt="" role="icon" />
              <SPrewrittenTextTitleText role="title">
                {translate('alarm_instructions')}
              </SPrewrittenTextTitleText>
            </SPrewrittenTextTitleWrapper>
            <SAlarmText role="alarmText" className="instructions">
              {instructions}
            </SAlarmText>
          </SPrewrittenTextWrapper>
        )}
        {hasCustomText && (
          <SCustomTextWrapper role="customTextContainer" $haveInstructions={!!instructions}>
            <SAlarmText role="alarmText">
              <HyperLinkText text={message.text} />
            </SAlarmText>
            <SCustomTextWrapperTime>{sentDateTime}</SCustomTextWrapperTime>
          </SCustomTextWrapper>
        )}
      </MessageContainer>
    </>
  );
};
