import styled from 'styled-components';
import { palette } from '../../theme/colors';
import SCheckbox from '../FilterItem/SBoxButton';

export const SmapItem = styled.div``;

export const SCheckBox = styled(SCheckbox)`
  width: 500px;
`;

export const SCheckBoxContainer = styled.div``;

export const SGroupIcon = styled.img`
  width: 2.6rem;
  height: 2.6rem;
`;

export const SFolderIcon = styled.img`
  height: 38%;
`;

export const SFileIcon = styled.img`
  height: 35%;
`;

export const Name = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const FoldersItemBox = styled.div`
  cursor: pointer;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${palette.pickedBluewood};
`;

export const SubFolderItem = styled.div`
  cursor: pointer;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InnerFolders = styled.div`
  width: 100%;
  display: flex;
  transition: 1s;
  min-height: 0px;
  align-items: center;
  padding-left: 20px;
  justify-content: space-between;
`;

export const SInnerFolders = styled.div`
  width: 100%;
  display: flex;
  transition: 1s;
  min-height: 0px;
  padding-left: 55px;
  align-items: center;
  justify-content: space-between;
`;

export const InnerItem = styled.div`
  width: 100%;
  margin: 15px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
`;

export const SArrowIcon = styled.div`
  height: 22px;
  width: 35px;
  cursor: pointer;
  transition: 0.1s;
`;

export const SLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${palette.pickedBluewood};
`;
