import styled, { css } from 'styled-components';
import { palette } from '../../theme/colors';

interface SContainerProps {
  $boldTitle?: boolean;
}

export const SContainer = styled.li<SContainerProps>`
  display: flex;
  align-items: center;

  text-decoration: none;

  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid ${palette.prussianBlue4};
  }

  padding: 1.1875rem 0rem;

  font-family: 'Roboto-Regular';

  .icon {
    width: 1.75rem;
  }
  .GROUP_FOLDER,
  .OFFLINE_GROUP_FOLDER {
    width: 2.8125rem;
  }

  img {
    flex-shrink: 0;
  }

  .title {
    color: ${palette.white};
    font-size: 0.875rem;
    font-weight: 400;

    ${props =>
      props.$boldTitle &&
      css`
        font-size: 1rem;
        font-weight: 500;
      `}
  }
  .subTitle {
    color: ${palette.silver};
    font-size: 0.75rem;
    font-weight: 400;
  }

  .title,
  .subTitle :not(:last-child) {
    margin-bottom: 0.25rem;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .favIcon {
    padding: 0rem 0.5rem 0rem 1.2rem;
  }

  cursor: pointer;
`;

export const SGrowDiv = styled.div`
  flex-grow: 1;
  min-width: 0;

  display: flex;
  flex-direction: column;
  margin: 0 1.125rem;
`;

export const SMoreOptionBtn = styled.button`
  background: none;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
`;

export const InitialsIcon = styled.div`
  background-color: ${palette.ashesGray};
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InitialsText = styled.p`
  text-transform: uppercase;
`;

export const PictureIcon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
`;
