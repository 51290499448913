import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Page } from '../../components/Page/Page';
import { palette } from '../../theme/colors';

export const SPage = styled(Page)`
  height: 100%;
  padding: 1.25rem 0 0 0;
  display: flex;
  flex-direction: column;
`;

export const SChatDetails = styled.div`
  padding: 0 1.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const AddBtn = styled(Link)`
  cursor: pointer;
  background-color: ${palette.honeyYellow};
  height: 32px;
  width: 32px;
  border-radius: 16px;
  margin-right: 0.5rem;
  align-content: center;
  justify-content: center;
  border: none;
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

export const SText = styled.p`
  margin: 1.25rem 0;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

export const SEnded = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: ${palette.stormGray};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
`;

export const SReadCountReplacer = styled.div`
  height: 1.25rem;
`;
