import * as React from 'react';
import Dots from '../../assets/imgs/documents/document-dots.svg';
import EmptyStar from '../../assets/imgs/documents/empty-star.svg';
import FilledStar from '../../assets/imgs/documents/filled-star.svg';
import { documentIcons, DocumentIconType } from './helpers';
import { Group } from '../../containers/GroupsList/groupsSlice/types';
import { HighlightSearchTerm } from '../Checklists/HighlightSearchTerm/HighlightSearchTerm';
import { BASE_URL } from '../../apis/utils/provider';
import {
  SContainer,
  SGrowDiv,
  SMoreOptionBtn,
  InitialsIcon,
  InitialsText,
  PictureIcon,
} from './DocumentItem.styles';

export interface IDocumentItemProps {
  group?: Group;
  title: string;
  subTitle?: string;
  itemId: number;

  iconVariant: DocumentIconType;

  boldTitle?: boolean;
  pdfLink?: string;

  onMoreOptionClick?: (itemId: number) => void;
  onFavClick?: (itemId: number, newFavValue: boolean) => void;
  onCLick?: (itemId: number) => void;
  favorite: boolean;
  searchTerm?: string;
}

export const DocumentItem = (props: IDocumentItemProps) => {
  const {
    group,
    itemId,
    subTitle,
    title,
    onMoreOptionClick,
    onCLick,
    boldTitle,
    iconVariant,
    pdfLink,
    favorite,
    searchTerm,
    onFavClick,
  } = props;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onMoreOptionClick) onMoreOptionClick(itemId);
  };

  const openDocument = () => {
    if (onCLick && !pdfLink) onCLick(itemId);
    if (pdfLink) window.open(`${BASE_URL}/Media${pdfLink}`, '_blank');
  };

  const toggleFavorite = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!itemId) return;
    onFavClick?.(itemId, !favorite);
  };

  const getNameInitials = () => {
    if (!group?.name) {
      return '';
    }

    const nameArray = group.name.split(' ');
    if (nameArray.length === 1) {
      return nameArray[0].charAt(0);
    } else if (nameArray.length > 1) {
      return nameArray[0].charAt(0) + nameArray[1].charAt(0);
    } else {
      return '';
    }
  };

  const getInitialsIcon = () => (
    <InitialsIcon role="initialsIcon">
      <InitialsText role="initialsText">{getNameInitials()}</InitialsText>
    </InitialsIcon>
  );
  const getPictureIcon = () => (
    <PictureIcon
      role="pictureIcon"
      src={group?.imageFileName || documentIcons[iconVariant]}
      alt={iconVariant}
    />
  );

  return (
    <SContainer
      $boldTitle={boldTitle}
      onClick={openDocument}
      role={`documentItem-${title}`}
      id={`documentItem-${itemId}`}
    >
      {group ? (
        group.imageFileName ? (
          getPictureIcon()
        ) : (
          getInitialsIcon()
        )
      ) : (
        <img
          role="docIcon"
          className={`icon ${iconVariant}`}
          src={documentIcons[iconVariant]}
          alt={iconVariant}
        />
      )}

      <SGrowDiv role="infoContainer">
        <p className="title" role="title">
          {HighlightSearchTerm(title, searchTerm)}
        </p>
        <p className={'subTitle'} role="subtitle">
          {subTitle}
        </p>
      </SGrowDiv>
      <img
        className="favIcon"
        src={favorite ? FilledStar : EmptyStar}
        onClick={toggleFavorite}
        alt=""
        role="favoriteIcon"
      />
      {onMoreOptionClick && (
        <SMoreOptionBtn onClick={handleClick} role="moreOptions">
          <img role="icon" src={Dots} alt="" />
        </SMoreOptionBtn>
      )}
    </SContainer>
  );
};
