import { ReadReceiptItem } from '../ChatDetails/ReadReceiptList';
import { ReadReceipt } from '../ChatsList/chatListSlice/types';

export const mapToReadReceiptItems = (readReceipt?: ReadReceipt): ReadReceiptItem[] => {
  const readRecipients =
    readReceipt?.reads.map(u => {
      return {
        displayName: u.name,
        read: true,
        firstRead: u.readTime,
        photoFileName: u.photoFileName ?? null,
        userID: u.userId,
        title: u.title,
        available: u.available,
      };
    }) || [];
  const unreadRecipients =
    readReceipt?.notReads.map(u => {
      return {
        displayName: u.displayName,
        photoFileName: u.photoFileName ?? null,
        read: false,
        firstRead: null,
        userID: u.userID,
        available: u.available,
      };
    }) || [];
  return [...readRecipients, ...unreadRecipients];
};
