import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NoMembers from '../../assets/images/person-with-binocular.svg';
import GroupInfoCard from '../../components/GroupInfoCard/GroupInfoCard';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { GroupType } from '../../utils/enums';
import {
  reset,
  selectGroupDetail,
  selectGroupDetailError,
  selectGroupDetailIsLoading,
  selectGroupMembersSortedSearched,
} from './groupDetailSlice';
import { fetchGroupDetail } from './groupDetailSlice/actionCreators';
import BigFloatButton from '../../components/BigFloatButton/BigFloatButton';
import { HighlightSearchTerm } from '../../components/Checklists/HighlightSearchTerm/HighlightSearchTerm';
import { GroupMemberItem } from '../../components/GroupMemberItem/GroupMemberItem';
import Loader from '../../components/Loader/Loader';
import { MemberFilter } from '../../components/MemberFIlter/MemberFilter';
import { FilterSection } from '../../components/SearchFilterBar/SearchFilterBar';
import { translate } from '../../utils/translate';
import {
  resetAll,
  setSelectedGroupType,
  setSelectedUsers,
} from '../CreateMessage/createMessageSlice';
import { setSelectedGroupsAction } from '../CreateMessage/createMessageSlice/actionCreators';
import { SSearchFilterBar } from '../GroupsList/GroupsList.styles';
import { checkGroupsType } from './helpers';
import { SelectListUser } from '../CreateMessage/createMessageSlice/types';
import { SMembersList, SEmptyListFallback, SMembersTitle, SPage } from './GroupDetail.styles';

export enum MemberFilterTypes {
  Name = 'groupInfoName',
  Title = 'groups_member_additionalInfo',
}

const MEMBER_FILTER_SECTIONS: FilterSection[] = [
  {
    title: 'groups_sortBy',
    type: 'radio',
    content: [
      { id: 0, name: MemberFilterTypes.Name, checked: true },
      { id: 1, name: MemberFilterTypes.Title, checked: false },
    ],
  },
];

export const GroupDetail = () => {
  const groupDetail = useAppSelector(selectGroupDetail);
  const isLoading = useAppSelector(selectGroupDetailIsLoading);
  const error = useAppSelector(selectGroupDetailError);

  const { name, imageFileName } = groupDetail || {};
  const location = useLocation();
  const [filterOpen, setFilterOpen] = useState(false);
  const [memberFilters, setMemberFilters] = useState<FilterSection[]>(
    JSON.parse(JSON.stringify(MEMBER_FILTER_SECTIONS))
  );
  const [sorting, setSorting] = useState<MemberFilterTypes>(MemberFilterTypes.Name);
  const [searchText, setSearchText] = useState('');
  const members = useAppSelector(selectGroupMembersSortedSearched(sorting, searchText));

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const isParamsTrueID = Number(id);
    if (!isParamsTrueID) {
      navigate('/groups', { replace: false });
    }

    dispatch(fetchGroupDetail({ id: +(id || 0) }));
    return () => {
      setMemberFilters(JSON.parse(JSON.stringify(MEMBER_FILTER_SECTIONS)));
      dispatch(reset());
    };
  }, [id]);

  const handleOpenFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const onSearch = (value: string) => {
    setSearchText(value);
  };

  const onSearchChanged = (value: string) => {
    onSearch(value);
  };

  const handleFilter = (memberFiltersNew: FilterSection[]) => {
    setMemberFilters(memberFiltersNew);
    memberFiltersNew[0].content.forEach(item => {
      if (item.checked) setSorting(item.name as MemberFilterTypes);
    });
    setFilterOpen(false);
  };

  const onMemberClick = (memberID: number) => {
    navigate(`memberSettings/${memberID}`, { replace: false });
  };

  const handleSendNewMessage = () => {
    if (!groupDetail) return;
    dispatch(resetAll());
    dispatch(
      setSelectedGroupsAction([
        {
          groupType: groupDetail?.groupType,
          id: groupDetail?.id,
          name: groupDetail?.name,
          isChecked: true,
        },
      ])
    );
    const users: SelectListUser[] = members.map(member => {
      const { userID, userName, userEmail, photoFileName, phoneNumber, groupID } = member;
      return {
        id: userID,
        displayName: userName,
        title: null,
        photoFileName: photoFileName,
        email: userEmail || '',
        creatorId: null,
        phoneNumber: phoneNumber,
        isSelected: true,
        groupIds: [groupID],
      } as SelectListUser;
    });
    dispatch(setSelectedUsers(users));

    if (groupDetail?.groupType === GroupType.Normal) {
      dispatch(setSelectedGroupType([GroupType.Normal]));
    }
    if (groupDetail?.groupType === GroupType.CoAlert) {
      dispatch(setSelectedGroupType([GroupType.CoAlert]));
    }
    if (groupDetail?.groupType === GroupType.CrossOrg) {
      dispatch(setSelectedGroupType([GroupType.CrossOrg]));
    }
    if (groupDetail?.groupType === GroupType.Hidden) {
      dispatch(setSelectedGroupType([GroupType.Hidden]));
    }

    navigate(`/createMessage/new`, {
      state: { prevPath: location.pathname, disableRecipientsChange: true },
    });
  };

  const isMembersEmpty = members.length === 0;

  if (isLoading.all) {
    return <Loader />;
  }

  if (error) {
    return <></>;
  }

  return (
    <SPage>
      {groupDetail && members && (
        <GroupInfoCard
          name={name || ''}
          membersLength={members.length}
          imageFileName={imageFileName || ''}
          type={translate(`${checkGroupsType(groupDetail.groupType)}`)}
        />
      )}
      <SSearchFilterBar
        onSearch={onSearchChanged}
        handleOpenFilter={handleOpenFilter}
        value={searchText}
      />
      <MemberFilter
        label={'sort'}
        isOpen={filterOpen}
        filters={memberFilters}
        setIsOpen={handleOpenFilter}
        setCheckedState={setMemberFilters}
        onFilter={groupFiltersNew => {
          handleFilter(groupFiltersNew);
        }}
      />
      {!isMembersEmpty && (
        <>
          <SMembersTitle> {translate(`groups_members`)} </SMembersTitle>
          <SMembersList id="groupMembersList">
            {members &&
              members.map((member, index) => (
                <GroupMemberItem
                  key={`${member.userID}-${index}`}
                  memberID={member.userID}
                  userName={member.userName}
                  title={HighlightSearchTerm(member.userName, searchText)}
                  subTitle={member.info}
                  date={member.lastAccess || ''}
                  onClick={onMemberClick}
                  isAdmin={!!member.admin}
                  isGroupHidden={groupDetail?.groupType === 2}
                  photoFileName={member.photoFileName || null}
                />
              ))}
          </SMembersList>
        </>
      )}
      <SEmptyListFallback
        src={NoMembers}
        listLength={members.length}
        isLoading={!!isLoading.all}
        searchTerm={searchText}
        emptyListTx={'group_empty_members'}
        noSearchTx={'group_empty_searched_members'}
        ignoreSearchTermInFallBackText
      />

      {!isMembersEmpty && <BigFloatButton tx="groups_sendMessage" onClick={handleSendNewMessage} />}
    </SPage>
  );
};
