import styled from 'styled-components';
import { Page } from '../../components/Page/Page';
import { palette } from '../../theme/colors';

export const SPage = styled(Page)`
  padding: 0;
`;

interface SUsersContainerProps {
  $height?: number;
}

export const SUsersContainer = styled.div<SUsersContainerProps>`
  flex-grow: 1;
  height: 100%;

  display: flex;
  flex-direction: column;

  background-color: ${palette.prussianBlue2};
  padding: 0 1rem;
  .usersCount {
    font-family: 'Roboto-Regular';
    font-weight: 400;
    font-size: 1rem;
    color: ${palette.white};
  }
`;

export const SUserList = styled.ul`
  list-style-type: none;
  padding-top: 1rem;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
