import * as React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { STextField, STextContainer } from './ConfirmDialog.styles';
import {
  SDialog,
  SOverLay,
  SCancelButton,
  SConfirmButton,
  STitle,
  SButtonWrapper,
  SDescription,
  SModalWrapper,
  SError,
} from './style';
import { TextFieldType } from '../TextField/TextField';
import Loader from '../Loader/Loader';

interface IConfirmDialogProps {
  isOpen: boolean;
  title: string;
  description?: string;
  onSubmit?: (text?: string) => void;
  onCancel?: () => void;
  dismiss: () => void;
  confirmText?: string;
  cancelText?: string;
  errorText?: string;
  isLoading?: boolean;
  inputBox?: boolean;
  inputType?: TextFieldType;
  initialInputValue?: string;
  placeholderTx?: string;
  confirmStyle?: 'standard' | 'green' | 'red' | 'fit-big-text';
  descriptionStyle?: 'fit-big-text';
  blockOutsideClick?: boolean;
  isConfirmDisabledWhenEmpty?: boolean;
}

const ConfirmDialog: React.FC<IConfirmDialogProps> = ({
  isOpen,
  title,
  description,
  onSubmit,
  onCancel,
  confirmText,
  cancelText,
  errorText,
  isLoading,
  dismiss,
  inputBox,
  inputType = TextFieldType.TEXT,
  placeholderTx,
  confirmStyle,
  descriptionStyle,
  initialInputValue,
  blockOutsideClick,
  isConfirmDisabledWhenEmpty,
}) => {
  const { t } = useTranslation();
  const titleTx = title ? t(`${title}`) : null;
  const confirmTx = confirmText ? t(`${confirmText}`) : t('ok');
  const cancelTx = cancelText ? t(`${cancelText}`) : t('cancel');
  const descTx = t(`${description}`);
  const dialogRef = React.useRef<HTMLDivElement>(null);
  const [text, setText] = React.useState<string | undefined>(initialInputValue);
  const isConfirmDisabled = isConfirmDisabledWhenEmpty && !text?.length;

  const handleClickOutside = (e: MouseEvent) => {
    if (dialogRef && !dialogRef.current?.contains(e.target as Node)) {
      dismiss();
    }
  };

  React.useEffect(() => {
    setText(initialInputValue);
  }, [initialInputValue]);

  React.useEffect(() => {
    if (document.addEventListener && !blockOutsideClick) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      if (document.removeEventListener) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockOutsideClick]);

  const handleSubmit = () => {
    if (isConfirmDisabled || !onSubmit) return;

    if (inputBox && text) {
      onSubmit(text);
      setText('');
    } else {
      onSubmit();
    }
  };

  return isOpen
    ? ReactDOM.createPortal(
        <React.Fragment>
          <SOverLay />
          <SModalWrapper role="confirmationDialog">
            <SDialog ref={dialogRef} role="dialogContent">
              {isLoading && <Loader />}
              {!isLoading && (
                <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
                  <STextContainer role="textContainer">
                    {titleTx && (
                      <STitle data-e2e={`${title ?? 'dialog'}_title`} role="title">
                        {titleTx}
                      </STitle>
                    )}
                    {description && (
                      <SDescription descriptionStyle={descriptionStyle} role="description">
                        {descTx}
                      </SDescription>
                    )}
                  </STextContainer>
                  {inputBox && (
                    <form
                      role="inputForm"
                      onSubmit={e => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <STextField
                        role="inputField"
                        type={inputType}
                        placeholder={t(`${placeholderTx}`)}
                        value={text}
                        onChange={e => {
                          setText(e.target.value);
                        }}
                      />
                    </form>
                  )}
                  {errorText && <SError role="errorText">{errorText}</SError>}
                  <SButtonWrapper role="buttonsWrapper" $haveError={!!errorText}>
                    {onCancel && (
                      <SCancelButton
                        data-e2e={`${title ?? 'dialog'}_cancelButton`}
                        role="cancelButton"
                        onClick={onCancel}
                      >
                        {cancelTx}
                      </SCancelButton>
                    )}
                    {onSubmit && (
                      <SConfirmButton
                        data-e2e={`${title ?? 'dialog'}_confirmButton`}
                        role="confirmButton"
                        onClick={handleSubmit}
                        confirmStyle={confirmStyle}
                        $disabled={isConfirmDisabled}
                      >
                        {confirmTx}
                      </SConfirmButton>
                    )}
                  </SButtonWrapper>
                </div>
              )}
            </SDialog>
          </SModalWrapper>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default ConfirmDialog;
