import { t } from 'i18next';
import { Container, SCircle, SStatusRow } from './UserProfileAvailabilityButton.style';
import ArrowRightImg from '../../assets/icons/chevron-right.svg';
import { getDateTextByLanguage } from '../SidebarAvailabilityButton/helpers';
import { useNavigate } from 'react-router-dom';

export interface UserProfileAvailabilityButtonProps {
  available?: boolean;
  unavailableDateTo?: string | null;
  allowEdit?: boolean;
}

const UserProfileAvailabilityButton = (props: UserProfileAvailabilityButtonProps) => {
  const { available = true, unavailableDateTo, allowEdit = false } = props;
  const navigate = useNavigate();

  const onClick = () => {
    if (!allowEdit) return;
    navigate('/sidebar/enable-not-available-mode', {
      state: { prevPath: location.pathname },
    });
  };

  return (
    <Container onClick={onClick} isClickable={allowEdit}>
      <SStatusRow>
        <SCircle isAvailable={available} />
        <p className="status">{`${t('profile_status')}: ${t(
          available ? 'profile_available' : 'profile_not_available'
        )}`}</p>
        {allowEdit && (
          <img height={20} className="editIcon" src={ArrowRightImg} alt="edit" role="editIcon" />
        )}
      </SStatusRow>
      {!available && unavailableDateTo && (
        <p className="endDate">
          {t('profile_not_available_mode_enabled_description', {
            dateTime: getDateTextByLanguage(unavailableDateTo),
          })}
        </p>
      )}
    </Container>
  );
};

export default UserProfileAvailabilityButton;
