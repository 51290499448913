import Country from '../../components/CountryCodeDropDown/resources/country';
import { CountryCode } from '../../components/CountryCodeDropDown/types';
import { ELanguages, EPhoneCodes } from '../../i18n';
import { ExtendedUserByIdType } from '../Login/LoginSlice/types';

export interface PhoneNumberShorten {
  country?: CountryCode;
  nationalNumber?: string;
  countryCallingCode?: string;
}

export const isNumbersOnly = (text: string): boolean => {
  const numRegex = /^\d+$/;

  return numRegex.test(text);
};

export const getPhoneNumber = (phone: string): PhoneNumberShorten | undefined => {
  const trimmedPhone = phone.trim();
  const country = Country.getCountryByNumber(trimmedPhone);
  if (country) {
    const { dial_code: dialCode, code } = country;
    const phoneNumber = dialCode ? trimmedPhone.slice(dialCode.length) : trimmedPhone;
    return { country: code, countryCallingCode: dialCode, nationalNumber: phoneNumber };
  }
  return { country: '' as CountryCode, nationalNumber: trimmedPhone };
};

const getPhoneDataFromLocale = (locale: ELanguages) => {
  const phoneCodeByLocale = (EPhoneCodes[locale] ?? EPhoneCodes.se) as CountryCode;
  const countryData = Country.getCountryDataByCode(phoneCodeByLocale);
  return getPhoneNumber(`${countryData?.dial_code}`);
};

export const getSecondaryPhoneNumber = (
  user: ExtendedUserByIdType | null,
  language: ELanguages
): PhoneNumberShorten | undefined => {
  const phoneNumbers = user?.phoneNumber.split(',') ?? [];
  const firstNumber =
    phoneNumbers.length > 0 && phoneNumbers[0].trim().length ? phoneNumbers[0] : undefined;
  const secondNumber =
    phoneNumbers.length > 1 && phoneNumbers[1].trim().length ? phoneNumbers[1] : undefined;
  let phoneData;
  if (secondNumber) {
    phoneData = getPhoneNumber(secondNumber);
  } else if (firstNumber) {
    phoneData = getPhoneNumber(firstNumber);
    if (phoneData) phoneData.nationalNumber = '';
  } else {
    phoneData = getPhoneDataFromLocale(language);
  }
  return phoneData;
};

export const getPhoneNumberStringByPhoneNumberObject = (
  phoneNumber: PhoneNumberShorten | undefined
) => {
  if (!phoneNumber) {
    return '-';
  }
  const { countryCallingCode, nationalNumber } = phoneNumber;
  return countryCallingCode && nationalNumber ? `${countryCallingCode} ${nationalNumber}` : '-';
};
