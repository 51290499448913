import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getActiveChecklist,
  getChecklistItems,
  getScrollToChecklistItemId,
  isChecklistsLoading,
  resetCheckListSelections,
  setChecklistItems,
  setScrollToChecklistItemId,
} from './checklistsSlice';
import {
  changeChecklistItemStatus,
  editChecklistItems,
  fetchChecklistItems,
  fetchContinueChecklist,
  fetchOneChecklist,
} from './checklistsSlice/actionCreators';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Dots from '../../assets/imgs/general/option-dots.svg';
import Loader from '../../components/Loader/Loader';
import { Checklist as ChecklistType, ChecklistItem } from './checklistsSlice/types';
import { ChecklistBottomSheet } from '../../components/Checklists/ChecklistBottomSheet';
import { ChecklistStatus } from '../../utils/enums';
import BigFloatButton from '../../components/BigFloatButton/BigFloatButton';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { translate } from '../../utils/translate';
import { getNumbersBasedDateTextByLanguage } from '../../utils/date';
import { Page } from '../../components/Page/Page';
import ChecklistDetails from '../../components/Checklists/ChecklistDetails/ChecklistDetails';
import SCheckbox from '../../components/FilterItem/SBoxButton';
import { t } from 'i18next';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import NameChecklistModal from '../../components/Checklists/NameChecklistModal';
import { trunctateText } from '../../utils/truncate';
import { useInterval } from '../../utils/customHooks/useInterval';
import { selectUserRoles } from '../Login/LoginSlice';
import { hasChecklistRole } from '../../components/Checklists/helpers';
import {
  SCheckboxButtonBlock,
  SChecklist,
  SItem,
  SDetails,
  UpperRow,
  SCommentAmount,
  SComment,
  SDots,
} from './Checklist.styles';
import isElectron from 'is-electron';

function Checklist() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const confirm = useConfirmation();
  const navigate = useNavigate();
  const layout = useLayoutContext();

  const [sortedItems, setSortedItems] = useState<ChecklistItem[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ChecklistItem | null>(null);
  const [addItemModalOpen, setAddItemModalOpen] = useState(false);
  const [newChecklistTaskName, setNewChecklistTaskName] = useState('');

  const checklist = useAppSelector(getActiveChecklist);
  const items = useAppSelector(getChecklistItems);
  const isLoading = useAppSelector(isChecklistsLoading);
  const scrollToChecklistItemId = useAppSelector(getScrollToChecklistItemId);
  const roles = useAppSelector(selectUserRoles);

  const isAllowedToReactivateEndedChecklist = hasChecklistRole(roles);

  const status = checklist?.status;
  const isCorrectChecklistLoaded = checklist?.id === Number(id);

  const types = {
    Checked: t('checklist_marked_as_done'),
    Unchecked: t('checklist_marked_as_undone'),
    Regular: t('checklist_regular'),
  };

  useEffect(() => {
    dispatch(fetchOneChecklist(Number(id)));
    dispatch(fetchChecklistItems(Number(id)));
    dispatch(resetCheckListSelections());

    return () => {
      dispatch(setChecklistItems([]));
    };
  }, [dispatch, id]);

  const getGroupLength = (selectedChecklist: ChecklistType) => {
    const length = selectedChecklist.sharedGroups.length;
    if (length === 1) {
      return `${length} ${translate('group')}`;
    }
    if (length > 1) {
      return `${length} ${translate('groups_title')}`;
    }
    return null;
  };

  const getUserLength = (selectedChecklist: ChecklistType) => {
    const length = selectedChecklist.usersCount;
    if (length === 1) {
      return `${length} ${translate('member')}`;
    }
    if (length > 1) {
      return `${length} ${translate('groups_members')}`;
    }
    return null;
  };

  const scrollToSelectedItem = () => {
    if (scrollToChecklistItemId) {
      const element = document.getElementById(`checklistItem-${scrollToChecklistItemId}`);
      if (element) {
        element.scrollIntoView();
        dispatch(setScrollToChecklistItemId(null));
      }
    }
  };

  useLayoutEffect(() => {
    if (checklist) {
      const subText = [];
      const groupLength = getGroupLength(checklist);
      const userLength = getUserLength(checklist);

      if (checklist.name) {
        layout.setMessage(checklist.name);
      }
      if (groupLength !== null) subText.push(groupLength);
      if (userLength !== null) subText.push(userLength);

      layout.setSubTitle(subText.join(', '));
    }
  }, [checklist]);

  useInterval(() => {
    if (!isElectron()) {
      dispatch(fetchOneChecklist(Number(id)));
      dispatch(fetchChecklistItems(Number(id), true));
    }
  }, 3000);

  useEffect(() => {
    const newItems = [...items];
    newItems.sort((a, b) => a.sortIndex - b.sortIndex);
    setSortedItems(newItems);

    if (selectedItem) {
      const index = newItems.findIndex(e => e.id === selectedItem.id);
      setSelectedItem(newItems[index]);
    }
    scrollToSelectedItem();
  }, [items]);

  const handleClickItem = (item: ChecklistItem | null) => {
    if (status === ChecklistStatus.NotStarted) {
      return;
    }
    setSelectedItem(item);
    setDrawerOpen(!drawerOpen);
  };

  const handleClickCheck = (itemId: number, checked: boolean) => {
    dispatch(changeChecklistItemStatus(Number(id), itemId, !checked));
  };

  const handleContinueChecklist = (checklistId?: number) => {
    if (!checklistId) {
      return;
    }

    confirm({
      title: 'checklist_confirmation',
      description: 'checklist_continue_confirmation',
      onSubmit: () => {
        dispatch(
          fetchContinueChecklist(checklistId, () => {
            setDrawerOpen(false);
            dispatch(fetchOneChecklist(Number(checklistId)));
            navigate(`/checklist/${checklistId}`);
          })
        );
      },
      onCancel: () => {},
      confirmText: 'checklist_reactivate',
    });
  };

  const handleActiveChecklist = () => {
    confirm({
      description: 'checklist_name_checklist_template',
      onSubmit: (text?: string) => {
        if (text && text.length > 0) {
          navigate(`start/${encodeURIComponent(text)}`);
        }
      },
      onCancel: () => {},
      confirmText: 'proceed',
      inputBox: true,
      inputBoxIntialValue: checklist?.name,
      placeholderTx: 'checklist_name_checklist_template',
      isConfirmDisabledWhenEmpty: true,
    });
  };

  const handleAddNew = () => {
    const tasks = [];
    sortedItems.forEach(item => {
      tasks.push({
        id: item.id,
        name: item.name,
        sortIndex: item.sortIndex,
        new: false,
      });
    });
    tasks.push({
      id: '',
      name: newChecklistTaskName,
      sortIndex: sortedItems.length + 1,
      new: true,
    });

    dispatch(
      editChecklistItems(Number(id), tasks, () => dispatch(fetchChecklistItems(Number(id))))
    );
    setAddItemModalOpen(false);
  };

  const renderCheckbox = (item: ChecklistItem) => {
    if (status === ChecklistStatus.Started) {
      return (
        <SCheckboxButtonBlock role="checboxButtonContainer">
          <SCheckbox
            stopPropagation
            onClick={() => handleClickCheck(item.id, item.complete)}
            isChecked={item.complete}
            disabled={false}
          />
        </SCheckboxButtonBlock>
      );
    }
    if (status === ChecklistStatus.NotStarted) {
      return <></>;
    }
    if (status === ChecklistStatus.Ended) {
      return (
        <SCheckboxButtonBlock role="checboxButtonContainer">
          <SCheckbox stopPropagation isChecked={item.complete} disabled={item.complete} grey />
        </SCheckboxButtonBlock>
      );
    }
  };

  const handleAddModal = () => {
    layout.toggleHeaderMenuVisability();
    setAddItemModalOpen(!addItemModalOpen);
  };

  const CheckDetails = useMemo(
    () => (
      <ChecklistDetails
        isOpen={layout.isMenuOpen}
        setIsOpen={layout.toggleHeaderMenuVisability}
        data={checklist}
        setAddItemModalOpen={handleAddModal}
      />
    ),
    [JSON.stringify(checklist?.sharedGroups), layout.isMenuOpen]
  );

  if (isLoading || !status || !isCorrectChecklistLoaded) return <Loader />;

  return (
    <>
      <Page>
        <SChecklist role="checklistsList">
          {sortedItems.map(item => {
            const hasComment = item.comments.length !== 0;
            const lastComment = hasComment ? item.comments[item.comments.length - 1] : null;
            const unread = false;
            const hasImg = hasComment && lastComment?.imageFileName ? true : false;

            return (
              <SItem
                id={`checklistItem-${item.id}`}
                key={item.id}
                checked={item.complete}
                ended={status === ChecklistStatus.Ended}
                onClick={() => handleClickItem(item)}
                className="checklist-item"
                role={`checklistItem-${item.name}`}
              >
                {renderCheckbox(item)}

                <SDetails role="details">
                  <UpperRow role="topRow">
                    <p role="name">{`${item.sortIndex}. ${item.name}`}</p>
                    {hasComment && (
                      <SCommentAmount role="commentsAmount">
                        {item.comments.length} {translate('checklist_comments')}
                      </SCommentAmount>
                    )}
                  </UpperRow>
                  {lastComment && (
                    <SComment unread={unread} role="commentContainer">
                      <p className="author-text" role="author">{`${lastComment.author}: ${
                        hasImg
                          ? translate(`imageReceived`, { count: 1 })
                          : lastComment.text === null
                          ? types[lastComment.type]
                          : trunctateText(lastComment.text, 38)
                      }`}</p>
                      <p className="time" role="time">
                        {getNumbersBasedDateTextByLanguage({
                          dateText: lastComment.sent,
                          showOnlyTimeIfToday: true,
                        })}
                      </p>
                    </SComment>
                  )}
                </SDetails>
                {status !== ChecklistStatus.NotStarted && (
                  <SDots src={Dots} alt="" onClick={() => {}} role="dotsIcon" />
                )}
              </SItem>
            );
          })}
        </SChecklist>
        {selectedItem && (
          <ChecklistBottomSheet
            isOpen={drawerOpen}
            toggleIsOpen={() => setDrawerOpen(prev => !prev)}
            selectedItem={selectedItem}
            status={status}
          />
        )}
        {status === ChecklistStatus.Ended && isAllowedToReactivateEndedChecklist && (
          <BigFloatButton
            className="reactiveButton"
            tx="checklist_action_continue"
            onClick={() => handleContinueChecklist(checklist?.id)}
          />
        )}
        {status === ChecklistStatus.NotStarted && (
          <BigFloatButton
            className="activateButton"
            tx="checklist_action_activate"
            onClick={() => handleActiveChecklist()}
          />
        )}
        {CheckDetails}
      </Page>
      {status === ChecklistStatus.Started && (
        <NameChecklistModal
          isOpen={addItemModalOpen}
          setIsOpen={setAddItemModalOpen}
          onAdd={handleAddNew}
          text={newChecklistTaskName}
          setText={setNewChecklistTaskName}
        />
      )}
    </>
  );
}

export default Checklist;
