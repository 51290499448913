import { DrawerModal } from '../DrawerModal/DrawerModal';
import { DataSelectableListType, SelectedAllType } from '../DocumentFilter/DocumentFilterV2';
import { useSelectlist } from '../../utils/customHooks/useSelectList';
import { translate } from '../../utils/translate';
import { SButton, SList } from '../DocumentFilter/DocumentFilter.styles';
import { SCheckBoxWithSubTitle, SMainTitle } from './AlarmFilter.styles';
import { palette } from '../../theme/colors';
import { isAccountSelectedAllOrPartial } from '../../common/groupsHelpers';
import { getSelectedDataValue } from '../DocumentFilter/helpers';
import { ResetFiltersButton } from '../Button/ResetFiltersButton';

export type AlarmFilterType = Set<number> | SelectedAllType;
export type AlarmFilterTypeObject = { selectedIds: AlarmFilterType };

export interface IAlarmFilterProps<T> {
  data?: T[];
  initialSelectedData?: Set<number>;

  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

  onFilter: (selectedIds: AlarmFilterType) => void;
  defaultFilters: AlarmFilterType;
  onChange?: (selectedIds: AlarmFilterType) => void;
}

export const AlarmFilter = <T extends DataSelectableListType>(props: IAlarmFilterProps<T>) => {
  const { isOpen, setIsOpen, onFilter, data, initialSelectedData, defaultFilters } = props;

  const {
    handleSelect,
    isStagedSelectedAll,
    isNonStagedSelected,
    stagedSelectedItems,
    onFinishSelecting,
    setStagedSelectedItems,
  } = useSelectlist({
    data: data ?? [],
    multiStage: true,
    initialSelected: initialSelectedData,
  });

  const onCloseHandle = () => {
    onFinishSelecting(false);

    setIsOpen(false);
  };

  const handleFilter = () => {
    onFilter(
      getSelectedDataValue({
        isAllDataSelected: isStagedSelectedAll,
        isNoneOfDataSelected: isNonStagedSelected,
        selectedData: stagedSelectedItems,
      })
    );
    onFinishSelecting(true);
  };

  const handleReset = () => {
    onFilter(defaultFilters);
    setStagedSelectedItems(new Set([]));
  };

  const isAllSelected = isAccountSelectedAllOrPartial(
    data?.map(item => item.id) ?? [],
    stagedSelectedItems as Set<number>
  );

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onCloseHandle}>
      <SMainTitle role="title">{translate('alarm_filter_by_account')}</SMainTitle>
      {data && (
        <SList className="SList" role="showContentOfCardContainer">
          <SCheckBoxWithSubTitle
            selected={isAllSelected.all}
            title={translate('select_all')}
            onToggleCheck={() => handleSelect()}
            valueId={0}
            checkBoxType={'box'}
            separatorColor={palette.prussianBlue5}
            clickAll
            $extraPadding
          />
          {data.map((item, index) => (
            <SCheckBoxWithSubTitle
              selected={stagedSelectedItems.has(item.id)}
              title={item.name}
              onToggleCheck={handleSelect}
              key={`${item.id}-${index}`}
              valueId={item.id}
              checkBoxType={'box'}
              withoutSeparator
              clickAll
            />
          ))}
        </SList>
      )}
      <ResetFiltersButton
        onClick={handleReset}
        isFullWidth
        marginTop="0.8125rem"
        isDisplayed={!isAllSelected.all}
      />
      <SButton $valid={!true} disabled={!true} tx={'messages_filter'} onClick={handleFilter} />
    </DrawerModal>
  );
};
