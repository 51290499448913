import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectUserById, selectUserByIdLoading } from '../Login/LoginSlice';
import { changeProfileImage, getCurrentUserById } from '../Login/LoginSlice/actionCreators';
import userImg from '../../assets/imgs/general/user-login.svg';
import EditImg from '../../assets/imgs/general/edit.svg';
import LockImg from '../../assets/icons/lock-with-three-dots.svg';
import ArrowRightImg from '../../assets/icons/chevron-right.svg';
import Loader from '../../components/Loader/Loader';
import { translate } from '../../utils/translate';
import { Page } from '../../components/Page/Page';
import { ChoosePhotoBottomSheet } from '../../components/ChoosePhotoBottomSheet/ChoosePhotoBottomSheet';
import { uploadImage } from '../../apis/mediaAPI';
import {
  getPhoneNumber,
  getPhoneNumberStringByPhoneNumberObject,
} from '../ChangeSecondaryNumber/helpers';
import {
  SUserImgContainer,
  SBottomText,
  SEditImage,
  SLink,
  SLinkContainer,
  SPropertyDisplay,
  STextContainer,
  STextFieldEndPlaceHolder,
  SUserImg,
  SUserProfileAvailabilityContainer,
} from './Profile.styles';
import UserProfileAvailabilityButton from '../../components/UserProfileAvailabilityButton/UserProfileAvailabilityButton';

export const Profile = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserById);
  const isLoading = useAppSelector(selectUserByIdLoading);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [profilePicURL, setProfilePicURL] = React.useState<string>();
  const [isLoadingPic, setIsLoadingPic] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [profilePic, setProfilePic] = React.useState<string | undefined>(user?.photoUrl);

  const isValid = !!profilePicURL && !isUploading;

  // backend sends two phone numbers separated with , and a space
  const phoneNumbers = user?.phoneNumber?.split(',');
  const primPhoneNumber = phoneNumbers && phoneNumbers[0];
  const secPhoneNumber = phoneNumbers && phoneNumbers?.length > 1 && phoneNumbers[1].substring(1);
  const primaryPhoneNumber = getPhoneNumber(primPhoneNumber || '');
  const secondPhoneNumber = getPhoneNumber(secPhoneNumber || '');

  const handleButtonClicked = () => {
    if (profilePicURL) {
      try {
        setIsLoadingPic(true);
        dispatch(
          changeProfileImage(profilePicURL === 'DELETE_PROFILE_PIC' ? null : profilePicURL)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        ).then(() => {
          setIsLoadingPic(false);
          setProfilePicURL('');
        });
      } catch (e) {
        setIsLoadingPic(false);
      }
    }
  };

  useEffect(() => {
    dispatch(getCurrentUserById());
  }, [dispatch]);

  useEffect(() => {
    handleButtonClicked();
  }, [isValid]);

  const deletePic = () => {
    setProfilePic(undefined);
    setProfilePicURL('DELETE_PROFILE_PIC');
  };

  const toggleIsOpen = React.useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const onChoosePic = async (picUrl: string, picFile?: File) => {
    setProfilePic(picUrl);
    if (picFile) {
      const imageFormData = new FormData();
      imageFormData.append('image', picFile, picFile.name);
      try {
        setIsUploading(true);
        const res = await uploadImage(imageFormData);
        setIsUploading(false);
        if (res.URL) setProfilePicURL(res.URL);
      } catch (e) {
        setIsUploading(false);
      }
    }
  };

  const cosafeLatestVersion = process.env.REACT_APP_COSAFE_LATEST_VERSION;

  if (isLoading || isLoadingPic || isUploading) return <Loader />;

  if (user) {
    return (
      <Page>
        <SUserImgContainer onClick={() => setIsOpen(!isOpen)} role="userImageContainer">
          <SUserImg
            src={user.photoUrl ? user.photoUrl : profilePic ? profilePic : userImg}
            alt=""
            role="userImage"
          />
          <SEditImage src={EditImg} alt="edit" role="editIcon" />
        </SUserImgContainer>
        <SUserProfileAvailabilityContainer>
          <UserProfileAvailabilityButton
            available={user?.available}
            unavailableDateTo={user?.unavailableDateTo}
            allowEdit
          />
        </SUserProfileAvailabilityContainer>

        <STextContainer role="infoContainer">
          <STextFieldEndPlaceHolder
            className="wrapper"
            $content={user.displayName ? undefined : translate('optional-brackets')}
            role="groupInfoNameContainer"
          >
            <SPropertyDisplay role="inputField">
              <label role="label">{translate('groupInfoName')}: </label>
              <p className="valueText" role="email">
                {user.displayName}
              </p>
            </SPropertyDisplay>
          </STextFieldEndPlaceHolder>
          <STextFieldEndPlaceHolder className="wrapper" role="groupMembersInfoContainer">
            <SPropertyDisplay role="inputField">
              <label role="label">{translate('groups_member_additionalInfo')}: </label>
              <p className="valueText" role="email">
                {user.title}
              </p>
            </SPropertyDisplay>
          </STextFieldEndPlaceHolder>

          <STextFieldEndPlaceHolder className="wrapper" role="emailContainer">
            <SPropertyDisplay role="inputField">
              <label role="label">{translate('profile_email')}: </label>
              <p className="valueText" role="email">
                {user.email}
              </p>
            </SPropertyDisplay>
          </STextFieldEndPlaceHolder>

          <SLinkContainer className="wrapper" role="phone1Container">
            <SLink $nonPointer to="" role="phone1Link">
              <label role="label">{translate('profile_info_phone')}: </label>
              <p className="phone-number" role="phoneNumber">
                {getPhoneNumberStringByPhoneNumberObject(primaryPhoneNumber)}
              </p>
            </SLink>
          </SLinkContainer>
          <SLinkContainer linkMode className="wrapper" role="phone2Container">
            <SLink
              $placeHolder={!secondPhoneNumber?.nationalNumber}
              to="/change-second-number"
              role="phone2Link"
            >
              <label role="label">{translate('profile_info_phone')}: </label>
              <p className="phone-number" role="phoneNumber">
                {getPhoneNumberStringByPhoneNumberObject(secondPhoneNumber)}
              </p>

              <img className="to-edit" src={ArrowRightImg} alt="edit" role="icon" />
            </SLink>
          </SLinkContainer>

          <SLink to="/change_password" className="wrapper" role="changePassword">
            <img src={LockImg} alt="lock" role="lockIcon" />
            {translate('profile_edit_password')}
            <img className="to-edit" src={ArrowRightImg} alt="edit" role="editIcon" />
          </SLink>
        </STextContainer>
        <ChoosePhotoBottomSheet
          isOpen={isOpen}
          toggleIsOpen={toggleIsOpen}
          onDeletePic={deletePic}
          didChoosePic={onChoosePic}
          choosedPic={!!profilePic || !!user?.photoUrl}
        />
        <SBottomText role="versionText">
          {translate('profile_info_version')}: {cosafeLatestVersion}
        </SBottomText>
      </Page>
    );
  } else {
    return <></>;
  }
};
