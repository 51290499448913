import { useTranslation } from 'react-i18next';
import { translate } from '../../utils/translate';
import { ReactComponent as GreenCheck } from '../../assets/imgs/chats/green-check.svg';
import { Org } from '../ChatsList/chatListSlice/types';
import { SOrganization } from './SentReceiptList.styles';
import {
  SSeen,
  SSeenContainer,
  SRecipient,
  SRecipientsList,
  SLine,
  Row,
  Column,
} from './ReadReceiptList.styles';
import { getNumbersBasedDateTextByLanguage } from '../../utils/date';

interface SentReceiptListProps {
  organizations: Org[];
  sentDate: string;
}

export const SentReceiptList = (props: SentReceiptListProps) => {
  const { organizations, sentDate } = props;

  const { t } = useTranslation();

  const getAccountType = (organization: Org) => {
    return organization.parentId
      ? `${translate('messages_broadcast_subaccount')}`
      : `${translate('messages_broadcast_account')}`;
  };

  return (
    <SRecipientsList role="recipientsList">
      {organizations.map((organization, key) => (
        <li key={key} role={`recipientContainer-${organization.name}`}>
          {key !== 0 && <SLine role="line" />}
          <SRecipient role="contentContainer">
            <Column role="innerContainer">
              <Row role="recipientInfoContainer">
                <p role="name">{organization.name}</p>
                <SOrganization role="organization">{getAccountType(organization)}</SOrganization>
              </Row>
              <SSeenContainer role="seenContainer">
                <SSeen seen={true} role="seenText">
                  {t('messages_recipient_sent_on')}{' '}
                  {getNumbersBasedDateTextByLanguage({ dateText: sentDate })}
                </SSeen>
                <div className="checkContainer" role="checkContainer">
                  <GreenCheck role="checkIcon" />
                </div>
              </SSeenContainer>
            </Column>
          </SRecipient>
        </li>
      ))}
    </SRecipientsList>
  );
};
