import isElectron from 'is-electron';
import styled from 'styled-components';
import { palette } from '../../../theme/colors';

export const SHeaderWrapper = styled.header`
  background-color: ${props =>
    isElectron() ? props.theme.palette.background.primary : palette.darkblack};
  position: fixed;
  top: 0;
  z-index: 9995;
  width: 100%;
  border-radius: 0 0 17px 17px;
`;

export const SHeader = styled.div`
  background-color: ${props => props.theme.palette.background.primary};
  display: flex;
  place-content: space-between;
  align-items: center;

  max-width: 26rem;
  margin: auto;
  padding: 2em;

  height: 4.81rem;

  svg {
    aspect-ratio: 1;
  }
`;

export const LanguageSelector = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .lang-select {
    height: 1.25rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .arrow {
      width: 0.5rem;
      z-index: 1000;
    }
  }
  :hover .lang-menu {
    visibility: visible;
  }
  :hover .arrow {
    transform: rotate(180deg);
  }

  .flag {
    border-radius: 3px;
    width: 30px;
    height: auto;
  }
  .lang-select {
    .gap {
      width: 0.6875rem;
    }
  }

  .lang-menu {
    position: absolute;
    width: 66px;
    background-color: ${palette.pickedBluewood};
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    visibility: hidden;

    margin: -0.5rem 0 0.5rem;
    padding: 0.5rem 0;
  }

  .flag-select {
    margin-right: 15px;

    :first-of-type {
      padding: 0;
    }
    img,
    svg {
      cursor: pointer;
      :hover {
        transform: scale(1.1);
      }
    }
  }

  .arrow {
    width: 0.5rem;
  }
`;

export const SBalanceSpace = styled.div`
  aspect-ratio: 1;
  width: 32px;
`;
