import styled, { css } from 'styled-components';
import { palette } from '../../theme/colors';

export const SChecklist = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;

  .checklist-item + * {
    margin-top: 10px;
  }

  /* vertical scrolling + hide scroller bar  */
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SCheckboxButtonBlock = styled.div`
  display: block;
  align-self: auto;
`;

export const SItem = styled.div<{ checked: boolean; ended: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 10px;
  padding: 1rem;
  align-items: flex-start;
  border: 1px solid transparent;
  cursor: pointer;

  p {
    padding-left: 0.75rem;
    font-family: 'Roboto-Regular';
    font-size: 14px;
  }

  ${props =>
    props.checked
      ? css`
          border: 1px solid ${palette.prussianBlue2};
        `
      : css`
          background-color: ${palette.prussianBlue};
        `}

  ${props =>
    props.ended &&
    css`
      background-color: ${props.checked ? palette.darkSlateLightGrey : palette.prussianBlue};
      border: 1px solid ${props.checked ? palette.checkedBorder : palette.prussianBlue};
    `}
`;

export const SDetails = styled.div`
  width: 100%;
  min-width: 0;
  align-self: center;
`;

export const SDots = styled.img`
  position: absolute;
  right: 11px;
  top: 15px;
  cursor: pointer;
`;

export const SCommentAmount = styled.div`
  padding-right: 1rem;
  font-size: 13px;
`;

export const UpperRow = styled.div`
  justify-content: space-between;
  color: ${() => palette.silver};
  display: flex;
  padding-right: 0.5rem;
`;

export const SComment = styled.div<{ unread: boolean }>`
  font-family: 'Roboto-Regular';
  font-size: 12px;
  display: flex;
  color: ${props => (props.unread ? palette.honeyYellow : palette.silver)};
  justify-content: space-between;
  padding-top: 0.5rem;

  .author-text {
    max-width: 75%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }
`;
