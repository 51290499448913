import React from 'react';
import { ChecklistStatus } from '../../../utils/enums';
import { SChecklistButton, SChecklistSettingsWrapper } from './ChecklistDetails.styles';
import { ReactComponent as Play } from '../../../assets/icons/play.svg';
import { ReactComponent as EditTemplateIcon } from '../../../assets/icons/pen.svg';
import { ReactComponent as EditAccessIcon } from '../../../assets/icons/silhouette-with-plus.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash-can--white.svg';
import { ReactComponent as EndChecklistIcon } from '../../../assets/imgs/general/cross.svg';
import { ReactComponent as AddSquare } from '../../../assets/icons/plus-square.svg';
import { ReactComponent as RenameIcon } from '../../../assets/icons/pen-with-line.svg';
import { useAppDispatch } from '../../../hooks';
import { useConfirmation } from '../../../utils/ConfirmationServiceContext/confirmationContext';
import {
  fetchContinueChecklist,
  fetchDeleteTemplate,
  fetchEndChecklist,
  renameAChecklist,
} from '../../../containers/Checklists/checklistsSlice/actionCreators';
import { useNavigate } from 'react-router-dom';
import { translate } from '../../../utils/translate';
import { GroupDetail } from '../../../containers/GroupDetail/groupDetailSlice/types';

interface ChecklistDetailsButtonsProps {
  canEdit: boolean | undefined;
  status: ChecklistStatus;
  insideChecklist: boolean;
  id: number;
  foundSharedGroups: GroupDetail[];
  setIsOpen: () => void;
  name: string;
  setAddItemModalOpen?: () => void;
}

function ChecklistDetailsButtons(props: ChecklistDetailsButtonsProps) {
  const { canEdit, status, insideChecklist, id, setIsOpen, name, setAddItemModalOpen } = props;

  const dispatch = useAppDispatch();
  const confirm = useConfirmation();
  const navigate = useNavigate();

  const handleEditAccess = (checklistId: number) => {
    navigate(`/checklist/${checklistId}/edit-access`, {
      state: {
        fromList: !insideChecklist,
        fromItem: insideChecklist,
      },
    });
  };

  const handleDeleteChecklist = (checklistId: number) => {
    confirm({
      title: 'checklist_confirmation',
      description: 'checklist_delete_confirmation',
      onSubmit: () => {
        dispatch(fetchDeleteTemplate(checklistId));
        setIsOpen();
      },
      onCancel: () => {},
      confirmText: 'messages_delete',
      confirmStyle: 'red',
    });
  };

  const handleCheckListEnd = (checklistId: number) => {
    dispatch(fetchEndChecklist(checklistId, navigate));
    setIsOpen();
  };

  const handleRename = (checklistId: number, newName: string) => {
    confirm({
      title: 'checklist_rename',
      inputBox: true,
      inputBoxIntialValue: newName,
      placeholderTx: 'checklist_rename',
      isConfirmDisabledWhenEmpty: true,
      onSubmit: text => {
        dispatch(renameAChecklist(checklistId, text!));
      },
      onCancel: () => {},
      confirmText: 'proceed',
    });

    setIsOpen();
  };

  const handleContinueChecklist = (checklistId: number) => {
    confirm({
      title: 'checklist_confirmation',
      description: 'checklist_continue_confirmation',
      onSubmit: () => {
        dispatch(
          fetchContinueChecklist(checklistId, () => {
            setIsOpen();
            if (insideChecklist) {
              navigate(0);
            } else {
              navigate(`/checklist/${checklistId}`);
            }
          })
        );
      },
      onCancel: () => {},
      confirmText: 'checklist_reactivate',
    });
  };

  const handleStartChecklist = (checklistId: number) => {
    confirm({
      description: 'checklist_name',
      onSubmit: (text?: string) => {
        if (text && text.length > 0) {
          navigate(`/checklist/${checklistId}/start/${encodeURIComponent(text)}`);
        }
      },
      onCancel: () => {},
      confirmText: 'proceed',
      inputBox: true,
      inputBoxIntialValue: name,
      placeholderTx: 'checklist_name',
      isConfirmDisabledWhenEmpty: true,
    });
  };

  const getOptionsForActiveChecklists = () => {
    const options = [];

    if (insideChecklist && canEdit) {
      options.push(
        <SChecklistButton
          key="addTaskButton"
          role="addTaskButton"
          onClick={() => {
            setAddItemModalOpen?.();
          }}
        >
          <p role="buttonText">{translate('checkListInfo_add_task')}</p>
          <AddSquare role="icon" />
        </SChecklistButton>
      );
    }

    if (canEdit) {
      options.push(
        <SChecklistButton
          key="editAccessButton"
          role="editAccessButton"
          onClick={() => {
            handleEditAccess(id);
          }}
        >
          <p role="buttonText">{translate('checklist_edit_acces')}</p>
          <EditAccessIcon role="icon" />
        </SChecklistButton>
      );

      options.push(
        <SChecklistButton
          key="renameButton"
          role="renameButton"
          onClick={() => {
            handleRename(id, name);
          }}
        >
          <p role="buttonText">{translate('checklist_rename')}</p>
          <RenameIcon role="icon" />
        </SChecklistButton>
      );

      options.push(
        <SChecklistButton
          key="endButton"
          role="endButton"
          onClick={() => {
            handleCheckListEnd(id);
          }}
        >
          <p role="buttonText">{translate('checklist_end_checklist')}</p>
          <EndChecklistIcon role="icon" />
        </SChecklistButton>
      );
    }

    return options;
  };

  const getOptionsForTemplates = () => {
    const options = [];

    options.push(
      <SChecklistButton
        key="startButton"
        role="startButton"
        onClick={() => handleStartChecklist(id)}
      >
        <p role="buttonText">{translate('checkist_start_template')}</p>
        <Play role="icon" />
      </SChecklistButton>
    );

    if (canEdit) {
      options.push(
        <SChecklistButton
          onClick={() => {
            handleEditAccess(id);
          }}
          key="editAccessButton"
          role="editAccessButton"
        >
          <p role="buttonText">{translate('checklist_edit_acces')}</p>
          <EditAccessIcon role="icon" />
        </SChecklistButton>
      );

      options.push(
        <SChecklistButton
          onClick={() =>
            navigate(`/checklist/${id}/edit`, {
              state: {
                fromList: !insideChecklist,
                fromItem: insideChecklist,
              },
            })
          }
          key="editTemplateButton"
          role="editTemplateButton"
        >
          <p role="buttonText">{translate('checklist_edit_template')}</p>
          <EditTemplateIcon role="icon" />
        </SChecklistButton>
      );

      options.push(
        <SChecklistButton
          key="deleteButton"
          role="deleteButton"
          onClick={() => handleDeleteChecklist(id)}
        >
          <p role="buttonText">{translate('checklist_action_delete')}</p>
          <DeleteIcon role="icon" />
        </SChecklistButton>
      );
    }

    return options;
  };

  const getOptionsForEndedChecklists = () => {
    const options = [];
    if (canEdit) {
      options.push(
        <SChecklistButton
          onClick={() => handleContinueChecklist(id)}
          key="continueButton"
          role="continueButton"
        >
          <p role="buttonText">{translate('checklist_action_continue')}</p>
          <Play role="icon" />
        </SChecklistButton>
      );
    }
    return options;
  };

  const getContent = () => {
    switch (status) {
      case ChecklistStatus.Started:
        const startedOptions = getOptionsForActiveChecklists();
        if (!startedOptions?.length) {
          return null;
        }
        return (
          <SChecklistSettingsWrapper button role="checklistDetailsButtons">
            {startedOptions}
          </SChecklistSettingsWrapper>
        );

      case ChecklistStatus.NotStarted:
        const templateOptions = getOptionsForTemplates();
        if (!templateOptions?.length) {
          return null;
        }
        return (
          <SChecklistSettingsWrapper button role="settingsWrapper">
            {templateOptions}
          </SChecklistSettingsWrapper>
        );

      case ChecklistStatus.Ended:
        const endedOptions = getOptionsForEndedChecklists();
        if (!endedOptions?.length) {
          return null;
        }

        return (
          <SChecklistSettingsWrapper button role="settingsEndWrapper">
            {endedOptions}
          </SChecklistSettingsWrapper>
        );
    }
  };

  const content = getContent();
  return content ? <div className="wrapper">{content}</div> : <></>;
}

export default ChecklistDetailsButtons;
