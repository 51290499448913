import isElectron from 'is-electron';
import * as React from 'react';
import { MenuItem } from '../../components/MenuItem/MenuItem';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useTranslation } from 'react-i18next';
import AlarmIcon from '../../assets/imgs/chats/alarm-bell-white.svg';
import {
  selectAlreadyopen,
  selectLoginIsLoading,
  selectUserMenuItems,
  selectUserOrganizationWebsite,
  selectUserRoles,
  setAlertMessage,
} from '../Login/LoginSlice';
import { menu } from './helper';
import { fetchTotalUnread } from '../ChatsList/chatListSlice/actionCreators';
import {
  selectMessagesActiveEmergancy,
  selectMessagesTotalUnread,
  selectActiveMusterCount,
  selectIsAvailable,
} from '../ChatsList/chatListSlice';
import { compact, findIndex } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import Loader from '../../components/Loader/Loader';
import { setSelectedOrgsAction } from '../Broadcast/broadcastSlice/actionCreators';
import { batch } from 'react-redux';
import { updateUser } from '../Login/LoginSlice/actionCreators';
import { SBlockedStateBanner, SListContainer, SPage, SSection } from './DashBoard.styles';
import { LoneWorkerTimerBanner } from '../../components/MessageItem/BlockedStateBanner/LoneWorkerTimerBanner';
import {
  getAlarmData,
  getIsDeactivated,
  setIsDeactivated,
} from '../LoneWorkerTimer/loneWorkerTimerSlice';
import { useEffect } from 'react';
import { fetchAlarmData } from '../LoneWorkerTimer/loneWorkerTimerSlice/actionCreator';
import { getItem } from '../../utils/storage';
import { ELanguages, addTranslations, getResourceBundle } from '../../i18n';
import { PushyConnectionBanner } from '../../components/MessageItem/BlockedStateBanner/PushyConnectionBanner';
import { NotificationsDisabledBanner } from '../../components/NotificationsDisabledBanner/NotificationsDisabledBanner';
import { UpdateAppRecommendationBanner } from '../../components/UpdateAppRecommendationBanner/UpdateAppRecommendationBanner';
import { selectIsAppUpdateRecommended } from '../CheckAppAvailable/checkAppAvailableSlice';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import { TextFieldType } from '../../components/TextField/TextField';
import { verifyPassword } from '../../apis/authApi/authAPI';

interface PasswordModalData {
  isOpen: boolean;
  value: string;
  isLoading?: boolean;
  errorMessage?: string;
}

const initialBroadcastPasswordModal: PasswordModalData = {
  isOpen: false,
  value: '',
};
export const DashBoard = () => {
  const navigate = useNavigate();
  const confirm = useConfirmation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectLoginIsLoading);
  const menuItems = useAppSelector(selectUserMenuItems);
  const roles = useAppSelector(selectUserRoles);
  const organizationWebsite = useAppSelector(selectUserOrganizationWebsite);
  const showUpdateAppRecommendationBanner =
    useAppSelector(selectIsAppUpdateRecommended) && isElectron();
  const isAvailable = useAppSelector(selectIsAvailable);
  const totalUnread = useAppSelector(selectMessagesTotalUnread);
  const activeMusters = useAppSelector(selectActiveMusterCount);
  const activeEmergancy = useAppSelector(selectMessagesActiveEmergancy);
  const alreadyOpen = useAppSelector(selectAlreadyopen);
  const loneWorkerAlarmData = useAppSelector(getAlarmData);
  const isLoneWorkerAlarmDeactivated = useAppSelector(getIsDeactivated);
  const { t } = useTranslation();
  const [broadcastPasswordModal, setBroadcastPasswordModal] = React.useState(
    initialBroadcastPasswordModal
  );

  const showUsingWebAppWarning = async () => {
    const initialLang = getItem('language') as ELanguages;
    const languageResourceBundle = await getResourceBundle(initialLang);
    if (!languageResourceBundle) {
      try {
        await addTranslations();
      } catch (error) {
        console.error(error);
        return;
      }
    }
    confirm({
      title: 'warning',
      confirmStyle: 'standard',
      confirmText: 'ok',
      onSubmit: () => {
        dispatch(setAlertMessage(false));
      },
      description: 'home_usingWebApp',
    });
  };

  React.useEffect(() => {
    batch(() => {
      if (!menuItems) dispatch(updateUser());
      dispatch(fetchTotalUnread());
      dispatch(setSelectedOrgsAction([]));
    });
  }, [dispatch]);

  React.useEffect(() => {
    if (alreadyOpen && !isElectron()) {
      showUsingWebAppWarning();
    }
    return () => {
      dispatch(setAlertMessage(false));
    };
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;
    if (isElectron()) {
      dispatch(fetchAlarmData(undefined, true));
      interval = setInterval(function () {
        if (localStorage.getItem('isLoneWorkTimerActive')) {
          dispatch(fetchAlarmData());
        } else {
          clearInterval(interval);
        }
      }, 10000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  //Hides lone worker alarm deactivated banner
  useEffect(() => {
    if (isElectron() && isLoneWorkerAlarmDeactivated) {
      setTimeout(() => {
        dispatch(setIsDeactivated(false));
      }, 5000);
    }
  }, [isLoneWorkerAlarmDeactivated]);

  const indexOfAlarm = findIndex(menuItems, item => item.technicalName === 'alarm');

  const menuList = menuItems?.map(item => {
    if (item.technicalName === 'organization_website') {
      if (organizationWebsite) {
        const organizationWebsiteItem = menu[item.technicalName];
        organizationWebsiteItem.navLink = organizationWebsite;
        return organizationWebsiteItem;
      }
      return null;
    }
    return menu[item.technicalName] || null;
  });

  const alarmMenu = menuList?.splice(indexOfAlarm, 1);

  const rolesList = roles?.map(role => {
    return menu[role] || null;
  });

  const navigateTo = (navLink: string) => {
    navigate(`/${navLink}`);
  };

  const submitPassword = async (text?: string) => {
    try {
      if (!text) return;
      setBroadcastPasswordModal({
        isOpen: true,
        value: text,
        errorMessage: undefined,
        isLoading: true,
      });
      await verifyPassword({ password: text });
      navigateTo('broadcast');
    } catch (err) {
      setBroadcastPasswordModal({
        isOpen: true,
        value: text ?? '',
        errorMessage: t('pw_verify_error'),
        isLoading: false,
      });
      console.error(err);
    }
  };

  const extendSelect = (navLink: string) => {
    if (navLink === 'broadcast') {
      setBroadcastPasswordModal({ isOpen: true, value: '' });
    } else if (navLink === 'panic/create' || navLink === 'aloneWorker/create') {
      confirm({
        title: 'alarm_warning',
        description: 'only_on_mobile',
        onSubmit: () => {},
      });
    } else {
      navigateTo(navLink);
    }
  };

  const ToExtendSelectMenus = isElectron()
    ? ['broadcast']
    : ['panic/create', 'aloneWorker/create', 'broadcast'];

  if (isLoading) return <Loader />;

  return (
    <SPage>
      {showUpdateAppRecommendationBanner && <UpdateAppRecommendationBanner />}
      {activeEmergancy && (
        <SBlockedStateBanner
          titleTx="alarm_in_progress"
          linkTx="message_go_link"
          className="dangerTheme"
          iconSource={AlarmIcon}
          onLinkClick={() => navigateTo('chat')}
        />
      )}
      {loneWorkerAlarmData && !showUpdateAppRecommendationBanner && (
        <LoneWorkerTimerBanner alarmData={loneWorkerAlarmData} />
      )}
      {isLoneWorkerAlarmDeactivated && !showUpdateAppRecommendationBanner && (
        <LoneWorkerTimerBanner isDeactivated />
      )}
      <PushyConnectionBanner />
      {!isAvailable && !showUpdateAppRecommendationBanner && (
        <NotificationsDisabledBanner margin="1rem 7% 0px" />
      )}
      <SSection role="buttonsContainer">
        <SListContainer role="buttonsList">
          {compact([
            ...(menuList?.filter(Boolean) || []),
            ...(rolesList?.filter(Boolean) || []),
            ...(alarmMenu?.filter(Boolean) || []),
          ]).map(item => {
            let notificationNum: number | null | '!' = null;
            if (item.tx === 'home_messages') {
              if (totalUnread !== 0) {
                notificationNum = totalUnread;
              } else {
                notificationNum = activeEmergancy ? '!' : null;
              }
            }
            if (item.tx === 'home_muster') {
              if (activeMusters && activeMusters !== 0) {
                notificationNum = activeMusters;
              }
            }
            return (
              <MenuItem
                role={`menuItem-${item.tx}}`}
                key={item.tx}
                disabled={
                  !isElectron() &&
                  (item.tx === 'home_panic_button' || item.tx === 'lone_worker_timer')
                }
                hidden={item.tx === 'home_groupAlarm'}
                titleText={t(item.tx)}
                navLink={
                  loneWorkerAlarmData && item.navLink == 'aloneWorker/create'
                    ? 'aloneWorker/timer'
                    : item.navLink
                }
                Icon={item.Icon}
                $needDangerStyle={item.tx === 'home_alarm'}
                notificationNum={notificationNum}
                isNotificationDanger={true}
                outsource={item.tx === 'home_organizationWebsite'}
                onSelect={
                  ToExtendSelectMenus.includes(item.navLink)
                    ? (navLink: string) => extendSelect(navLink)
                    : undefined
                }
              />
            );
          })}
        </SListContainer>
      </SSection>
      <ConfirmDialog
        isOpen={broadcastPasswordModal.isOpen}
        onCancel={() => setBroadcastPasswordModal(initialBroadcastPasswordModal)}
        onSubmit={submitPassword}
        dismiss={() => setBroadcastPasswordModal(initialBroadcastPasswordModal)}
        inputBox
        inputType={TextFieldType.PASSWORD}
        initialInputValue={broadcastPasswordModal?.value}
        confirmText="proceed"
        title="messages_broadcast_password_confirmation_modal_intro"
        placeholderTx="pw_verify_input_holder"
        isLoading={broadcastPasswordModal?.isLoading}
        errorText={broadcastPasswordModal?.errorMessage}
        isConfirmDisabledWhenEmpty
      />
    </SPage>
  );
};
