import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import Options from '../../components/Options/Options';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  isChatsLoading,
  selectCurrentChat,
  selectCurrentChatReplies,
  selectError,
  selectMessagesRecipients,
  setCurrentChatReplies,
} from '../ChatsList/chatListSlice';
import {
  fetchCurrentChat,
  getMessagesRecipientsAction,
  deleteAMessage,
  endAlertAction,
  changeReplyType,
  changeChatSubject,
} from '../ChatsList/chatListSlice/actionCreators';
import { selectUser, selectUserRoles } from '../../containers/Login/LoginSlice';
import Delete from '../../assets/imgs/chats/delete.svg';
import Lock from '../../assets/imgs/chats/lock.svg';
import UnLock from '../../assets/imgs/chats/unlock.svg';
import ImageIcon from '../../assets/imgs/chats/image.svg';
import EditIcon from '../../assets/imgs/chats/edit.svg';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { isEqual } from 'lodash';
import {
  fetchGroupMembers,
  setSelectedGroupsAction,
  setSelectedUsersAction,
} from '../CreateMessage/createMessageSlice/actionCreators';
import { createMessageIsLoading, selectGroupMembers } from '../CreateMessage/createMessageSlice';
import { messageDetailsIsLoading } from './chatDetailsSlice';
import {
  GroupType,
  ReceivedMessageType,
  ReplyToMessageType,
  SentMessageType,
} from '../../utils/enums';
import { Chat } from '../ChatsList/chatListSlice/types';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import BigFloatButton from '../../components/BigFloatButton/BigFloatButton';
import { getMessageHeaderSubTitle } from '../Chat/helpers';
import { SChatDetails, SEnded, SPage, SText, SReadCountReplacer } from './ChatDetails.styles';
import { areAllConditionsTruth } from '../../utils/conditions';
import { ReadReceiptList } from './ReadReceiptList';
import { SentReceiptList } from './SentReceiptList';
import { RecipientsSummaryList } from '../../components/RecipientsSummaryList/RecipientsSummaryList';

interface ChatDetailsProps {
  optionsOpen: boolean;
  setOptionsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClickOptions: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAlarmActive: React.Dispatch<React.SetStateAction<boolean>>;
  fromHoldingStatment?: boolean;
}

interface OptionsListItem {
  name: string;
  icon: string;
  callback?: () => void;
  disabled?: boolean;
  disabledTx?: string;
}

function ChatDetails(props: ChatDetailsProps) {
  const { optionsOpen, setOptionsOpen, onClickOptions, setIsAlarmActive, fromHoldingStatment } =
    props;

  const navigate = useNavigate();
  const confirm = useConfirmation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { id } = useParams();
  const layout = useLayoutContext();

  const user = useAppSelector(selectUser);
  const message = useAppSelector(selectCurrentChat);
  const replies = useAppSelector(selectCurrentChatReplies);
  const recipients = useAppSelector(selectMessagesRecipients);
  const groupsMembers = useAppSelector(selectGroupMembers);
  const isLoading = useAppSelector(createMessageIsLoading);
  const isMessageDetailsLoading = useAppSelector(messageDetailsIsLoading);
  const isFetchingMessageLoading = useAppSelector(isChatsLoading);
  const roles = useAppSelector(selectUserRoles);
  const error = useAppSelector(selectError);

  const [isAdmin, setIsAdmin] = useState(false);

  const isMessagesBlocked =
    message?.replyType === ReplyToMessageType.None ||
    message?.replyType === ReplyToMessageType.SenderOnly;
  const isSender = isEqual(message?.senderID, user?.id);

  const isAlarm = message?.type === ReceivedMessageType.Alarm;
  const isIamOkOrOnCallAlert = !!message?.musterID || !!message?.onCallAlertID;
  const isOnCallAlertOeIamokEnded = message?.ended;
  const disableDeleteOption =
    (isAlarm && !message?.recalled) || (isIamOkOrOnCallAlert && !isOnCallAlertOeIamokEnded);

  const canDeleteMessage = isSender || roles?.includes('DeleteMessage');
  // i think isAdmin needs optimization
  // it could be from backend and that's better
  // it could be done by checking the groups and check if the user is admin in at least one group , i don't know exactly
  const isRepliesEmpty = replies.length === 0;
  const isNoReplyTypeInitially = message?.replyType === ReplyToMessageType.None && isRepliesEmpty;
  const isMessageSenderOnly = message?.replyType === ReplyToMessageType.SenderOnly;
  const canBlockMessage = isSender || isAdmin || roles?.includes('ChangeMessageType');

  const isBroadCastMessage = message?.type === ReceivedMessageType.Broadcast;
  const isIamOkMessage = !!message?.musterID;
  const isOnCallAlertMessage = !!message?.onCallAlertID;
  const isHoldingStatmentMessage = message?.type === SentMessageType.HoldingStatement;

  const handleDelete = () => {
    if (!message) return;
    confirm({
      title: 'messages_delete_title',
      description: 'messages_deleteMessage',
      onSubmit: () => {
        dispatch(deleteAMessage(message.id, navigate, false, fromHoldingStatment));
        setOptionsOpen(false);
      },
      onCancel: () => {
        setOptionsOpen(false);
      },
      confirmText: 'messages_delete',
      cancelText: 'cancel',
    });
  };

  const handleViewMedia = () => {
    dispatch(setCurrentChatReplies([]));
    navigate(`/message/${id}/media`);
  };

  const handleBlockChange = (_isBlocked: boolean) => () => {
    if (!message) return;
    dispatch(
      changeReplyType(
        {
          id: message.id,
          model: { replyType: _isBlocked ? 2 : 0 },
        },
        id || ''
      )
    );

    setOptionsOpen(false);
  };

  const getOptionsItems = () => {
    const itemsList: OptionsListItem[] = [];

    //Allow edit title only for Regular messages, Broadcast and Holding Statement
    const showEditSubject =
      (isSender || isAdmin) &&
      !isIamOkMessage &&
      !isOnCallAlertMessage &&
      !isAlarm &&
      !message?.checklistID;

    if (showEditSubject) {
      itemsList.push({
        name: 'edit_chat_subject',
        icon: EditIcon,
        callback: () => {
          confirm({
            title: 'edit_chat_subject',
            isConfirmDisabledWhenEmpty: true,
            onSubmit: (subject?: string) => {
              if (!subject?.length) return;
              setOptionsOpen(false);
              dispatch(changeChatSubject(subject));
            },
            onCancel: () => {},
            confirmText: 'proceed',
            inputBox: true,
            inputBoxIntialValue: message?.subject ?? '',
            placeholderTx: 'edit_chat_subject',
          });
        },
      });
    }

    const showBlockOption =
      !isBroadCastMessage &&
      !isHoldingStatmentMessage &&
      !isNoReplyTypeInitially &&
      !isMessageSenderOnly &&
      canBlockMessage;

    if (showBlockOption) {
      itemsList.push({
        name: isMessagesBlocked ? 'messages_unblock' : 'messages_block',
        icon: isMessagesBlocked ? UnLock : Lock,
        callback: handleBlockChange(!isMessagesBlocked),
      });
    }
    itemsList.push({
      name: 'view_media',
      icon: ImageIcon,
      callback: () => handleViewMedia(),
    });
    if (canDeleteMessage) {
      itemsList.push({
        name: 'messages_delete',
        icon: Delete,
        callback: handleDelete,
        disabled: disableDeleteOption,
        disabledTx: 'alarm_delete_blocked',
      });
    }
    return itemsList;
  };

  const setHeader = (_message: Chat) => {
    let subText = '';
    if (_message.type === 2 && !_message.recalled) {
      setIsAlarmActive(true);
    } else {
      setIsAlarmActive(false);
    }
    if (_message.type === 9) {
      // holding statement
      if (_message.subject) {
        layout.setMessage(_message.subject);
      }
    }
    subText = getMessageHeaderSubTitle(_message, t, ' / ');
    layout.setSubTitle(subText);
    if (getOptionsItems().length !== 0) layout.setDoShowDots(true);
  };

  const getGroupInfo = () => {
    dispatch(getMessagesRecipientsAction(Number(id)));
  };

  useEffect(() => {
    if (message) {
      setHeader(message);
      getGroupInfo();
    } else {
      dispatch(fetchCurrentChat(id || ''));
    }

    const groupIds = message?.groupIDs;
    if (groupIds) {
      dispatch(fetchGroupMembers(groupIds));
    }
  }, [message]);

  useEffect(() => {
    const foundMemberData = groupsMembers.find(member => isEqual(member.userID, user?.id));
    if (foundMemberData) {
      setIsAdmin(foundMemberData.admin ? true : false);
    }
  }, [groupsMembers]);

  //Cleanup
  useEffect(() => {
    dispatch(setSelectedGroupsAction([]));
    dispatch(setSelectedUsersAction([]));
    return () => {
      fetchGroupMembers([]);
    };
  }, []);

  useEffect(() => {
    if (error === 'Message is deleted') {
      confirm({
        title: 'message_not_found_title',
        description: 'message_deleted',
        onSubmit: () => navigate('/chat'),
        confirmText: 'ok',
        blockOutsideClick: true,
      });
    }
  }, [error]);

  // check if messages groups has any groups which is (hidden, CoAlert or CrossOrg)
  const hasNoNormalGroupTypes = message?.groups
    ? message?.groups.find(group => group.type !== GroupType.Normal)
    : false;
  const isNonAlarmSender = !isAlarm && !isBroadCastMessage && isSender;
  const haveAddRecipientsToAnyMessageRole = roles?.includes('AddRecipientsToAnyMessage');

  const showAddGroupsBtn = areAllConditionsTruth([
    haveAddRecipientsToAnyMessageRole || isNonAlarmSender,
    !hasNoNormalGroupTypes,
    !isOnCallAlertMessage,
    !isIamOkMessage,
    !isBroadCastMessage,
  ]);

  const showEndBtn =
    message?.type === 2 && !message?.recalled && (isSender || roles?.includes('EndAlarm'));

  const handleEnd = () => {
    if (!message) return;
    confirm({
      title: 'messages_delete_title',
      description: 'messages_endAlarmText',
      onSubmit: () => {
        dispatch(endAlertAction(message.id));
      },
      onCancel: () => {},
      confirmText: 'messages_endAlarm',
      cancelText: 'cancel',
      confirmStyle: 'red',
    });
  };

  if (!message || isMessageDetailsLoading) {
    return <Loader />;
  }

  if (isLoading || isFetchingMessageLoading) {
    return <Loader />;
  }

  return (
    <SPage>
      <Options
        isOpen={optionsOpen}
        setIsOpen={onClickOptions}
        setTabBar
        items={getOptionsItems()}
      />
      <SChatDetails role="chatDetails">
        <RecipientsSummaryList
          data={message?.groups ?? []}
          handleAddClick={
            showAddGroupsBtn
              ? () =>
                  navigate(`/message-details/${id}/addRecipients`, {
                    state: { prevPath: location.pathname },
                  })
              : undefined
          }
        />
        {message.organizations.length ? (
          <>
            <SReadCountReplacer />
            <SentReceiptList organizations={message.organizations} sentDate={message.sent} />
          </>
        ) : (
          <>
            <SText role="recipientsReadCount">
              {message.recipientReadCount} {t(`messages_out`)} {message.recipientCount}{' '}
              {t(`messages_red`)}
            </SText>
            <ReadReceiptList recipients={recipients} />
          </>
        )}
        {message.type === ReceivedMessageType.Alarm && message.recalled && !optionsOpen && (
          <SEnded role="endedContainer">
            <p role="endedText">{t('messages_alert_canceled')}</p>
          </SEnded>
        )}
      </SChatDetails>
      {showEndBtn && <BigFloatButton onClick={handleEnd} tx="checklist_action_end" color="red" />}
    </SPage>
  );
}

export default ChatDetails;
