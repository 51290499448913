import * as React from 'react';
import GroupsImagePlaceHolder from '../../assets/imgs/general/groups-place-holder.svg';
import Dots from '../../assets/imgs/documents/documents_dots.svg';
import { getImage } from '../../apis/mediaAPI';
import { HighlightSearchTerm } from '../Checklists/HighlightSearchTerm/HighlightSearchTerm';
import { decode } from 'html-entities';
import {
  SEmptyTitleContainer,
  SContainer,
  SWrapper,
  SCardHeader,
  SSubjectText,
  SImage,
  STitle,
  SCardBody,
  SMediaContainer,
  STitlesContainer,
  PlaceHolderWrapper,
  SMoreOptionBtn,
} from './HoldingStatementCard.styles';

export interface IHoldingStatementCardProps {
  date: string | undefined;
  time: string | undefined;
  title: string;
  subTitle: string;
  subTitleName: string;
  subject: string;
  media: string[];
  id: number;
  onClick: (id: number) => void;
  searchText?: string;
  onMoreOptionClick?: (itemId: number) => void;
}

export const HoldingStatementCard = (props: IHoldingStatementCardProps) => {
  const {
    date,
    media,
    subTitle,
    subTitleName,
    subject,
    time,
    title,
    id,
    onClick,
    searchText,
    onMoreOptionClick,
  } = props;
  const [photos, setPhotos] = React.useState<string[]>();

  const init = async () => {
    if (media.length !== 0) {
      try {
        const photoList = await Promise.all(media.map(name => getImage({ imageName: name })));
        setPhotos(photoList);
      } catch (e) {}
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  const mediaSource = photos && photos.length !== 0 ? photos : [GroupsImagePlaceHolder];

  const hasMediaSource = !!photos && photos.length !== 0;

  const handleClick = () => {
    onClick(id);
  };

  const handleMoreClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onMoreOptionClick) {
      onMoreOptionClick(id);
    }
  };
  return (
    <SWrapper id={`holdingStatementCard-${id}`} role={`holdingStatementCard-${subject}`}>
      <SContainer onClick={handleClick} role="infoContainer">
        <SCardHeader role="cardHeader">
          {/* to prevent the design from failing if the title subject  "" don't know if it's needed or not  */}
          {subject ? <SSubjectText role="subject">{subject}</SSubjectText> : <div />}
          {date && (
            <p className="statment-date" role="date">
              {date}
            </p>
          )}
        </SCardHeader>
        <SCardBody role="cardBody">
          {hasMediaSource ? (
            <SMediaContainer role="mediaContainer">
              {mediaSource.map((image, index) => (
                <SImage role="image" key={`${index}`} index={index} src={image} alt={title} />
              ))}
            </SMediaContainer>
          ) : (
            <PlaceHolderWrapper role="placeholderImage" src={GroupsImagePlaceHolder} alt="" />
          )}

          <STitlesContainer mediaLenght={mediaSource.length} role="titlesContainer">
            {/* to prevent the design from failing if the title recived  "" don't know if it's needed or not  */}
            {title ? (
              <STitle role="title">{title}</STitle>
            ) : (
              <SEmptyTitleContainer role="emptyTitleContainer" />
            )}
            <p role="subTitleTextRow">
              <span className="subTitleName">{subTitleName}:</span>{' '}
              <span className="subTitle">{HighlightSearchTerm(decode(subTitle), searchText)}</span>
            </p>
          </STitlesContainer>
          {time && (
            <p className="statment-time" role="time">
              {time}
            </p>
          )}
        </SCardBody>
      </SContainer>
      <SMoreOptionBtn role="moreButton" onClick={handleMoreClick}>
        <img role="moreIcon" src={Dots} alt="" />
      </SMoreOptionBtn>
    </SWrapper>
  );
};
