import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SProfilePictureWrapper,
  SItemContainer,
  SLeft,
  SItem,
  SCenterContainer,
} from './FilterCheckboxItem.styles';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import { HighlightSearchTerm } from '../Checklists/HighlightSearchTerm/HighlightSearchTerm';
import SCheckbox from './SBoxButton';
import SRadio from './SRoundButton';
import AvailabilityChip from '../AvailabilityChip/AvailabilityChip';

export interface FilterItemProps {
  checked: boolean;
  name: string | undefined;
  setSelected: (e: SyntheticEvent) => void;
  id: number;
  type: 'box' | 'circle';
  image?: string | null;
  hasImage?: boolean;
  isGroupImg?: boolean;
  className?: string | undefined;
  showAvailability?: boolean;
  searchTerm?: string;
}

function FilterCheckboxItem(props: FilterItemProps) {
  const {
    name,
    setSelected,
    checked,
    type,
    hasImage,
    image,
    isGroupImg,
    className,
    showAvailability,
    searchTerm,
  } = props;

  const { t } = useTranslation();
  const buttonText = t(`${name}`);

  return (
    <SItemContainer className={className} onClick={setSelected} role={`checkboxItem-${buttonText}`}>
      <SLeft role="leftContainer">
        {hasImage && (
          <SProfilePictureWrapper>
            <ProfilePicture diameter={55} profilePictureFileName={image!} isGroup={isGroupImg} />
          </SProfilePictureWrapper>
        )}
        <SCenterContainer>
          <SItem role="buttonText">{HighlightSearchTerm(buttonText, searchTerm)}</SItem>
          {showAvailability && <AvailabilityChip isAvailable={false} />}
        </SCenterContainer>
      </SLeft>

      {type === 'box' ? <SCheckbox isChecked={checked} /> : <SRadio isChecked={checked} />}
    </SItemContainer>
  );
}

export default FilterCheckboxItem;
