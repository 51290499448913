import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PasswordImg from '../../assets/imgs/login/login-password.svg';
import UserNameImg from '../../assets/imgs/login/login-username.svg';
import userImg from '../../assets/imgs/login/two-factor-authentication.svg';
import Loader from '../../components/Loader/Loader';
import {
  isDevRelease,
  isTestApiUrlApplied,
  removeTestApiUrl,
  setTestApiUrl,
} from '../../configurations';
import useForm from '../../utils/customHooks/useForm';
import { getItem } from '../../utils/storage';
import { translate } from '../../utils/translate';
import { emailSchema } from '../../utils/validate';
import { setEmail, setPassword } from '../Login2fa/Login2faSlice';
import { getSsoRedirectLink } from '../OpenMobileApp/helpers';
import {
  SButton,
  SErroText,
  SFaTwoEmptyBox,
  SForgotPassLink,
  SForm,
  SLoginEmptyBox,
  SPage,
  SPrivacyText,
  SSSoLink,
  STextContainer,
  STextField,
  STitle,
  SUserImg,
} from './Login.styles';
import { selectLoginError, selectLoginIsLoading, setAlertMessage, setIsError } from './LoginSlice';
import { getSsoUrl, login } from './LoginSlice/actionCreators';
import { TextFieldType } from '../../components/TextField/TextField';

interface LoginProps {
  mode?: 'login' | 'sso';
}

export interface LoginForm {
  code?: string;
  email: string;
  password: string;
}

export interface STextFieldParams {
  error: boolean;
}

export interface SButtonParams {
  $error?: boolean;
  $textOnly?: boolean;
}

export const Login = (props: LoginProps) => {
  const { mode = 'login' } = props;

  const isLogin = mode === 'login';
  const isSso = mode === 'sso';
  const { inputs, handleChange, handleBlur, touched, isValid } = useForm<LoginForm>({
    initial: {
      email: '',
      password: '',
    },
    validateSchema: {
      email: emailSchema,
    },
  });
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const loginError = useSelector(selectLoginError);
  const isLoading = useSelector(selectLoginIsLoading);
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  const navigate = useNavigate();

  useEffect(() => {
    getSsoRedirectLink(searchParams, true, dispatch);
    //this function dispatches the SsoLogin function if user is not on mobile
  }, [searchParams]);

  useEffect(() => {
    dispatch(setAlertMessage(true));
    dispatch(setEmail(''));
    dispatch(setPassword(''));
    dispatch(setIsError(null));
  }, [location, dispatch]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isLogin) {
      dispatch(login({ email: inputs.email, password: inputs.password }));
    }
    if (isSso) {
      dispatch(getSsoUrl(inputs.email));
    }
  };

  const user = getItem('user');

  useEffect(() => {
    if (user?.TwoFactorAuthCodeRequired === true) {
      dispatch(setEmail(inputs.email));
      dispatch(setPassword(inputs.password));
      navigate('/2fa', { replace: true });
    } else {
      if (from !== '/') {
        if (user) navigate(from, { replace: true });
      } else {
        if (user) navigate('/dashboard', { replace: true });
      }
    }
  }, [user]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e as React.ChangeEvent<HTMLInputElement>);

  const [url, setUrl] = useState<string>();
  const handleApplyTestUrl = async () => {
    if (url) {
      setTestApiUrl(url);
      setUrl('');
      alert('done');
    }
  };
  const handleRemoveTestUrl = async () => {
    await removeTestApiUrl();
    // to force rerender component
    setUrl(prev => (prev === '' ? undefined : ''));
  };

  const onUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  if (isLoading) return <Loader />;
  return (
    <SPage>
      <SUserImg src={userImg} alt="" />
      <STitle>{translate('login_title')}</STitle>
      <SForm onSubmit={handleSubmit}>
        <STextField
          isShadow
          error={!!loginError}
          StartAdornment={<img src={UserNameImg} alt="username icon" />}
          name="email"
          aria-label="email"
          className="email"
          placeHolderTx="login_email"
          value={inputs.email}
          onChange={onChange}
          onBlur={handleBlur}
          touched={touched.email ?? false}
        />

        {isLogin ? (
          <STextField
            isShadow
            error={!!loginError}
            StartAdornment={<img src={PasswordImg} alt="password icon" />}
            type={TextFieldType.PASSWORD}
            aria-label="password"
            name="password"
            placeHolderTx="login_password"
            value={inputs.password}
            onChange={onChange}
            onBlur={handleBlur}
            touched={touched.password ?? false}
          />
        ) : (
          <>
            {loginError ? <SErroText>{loginError}</SErroText> : <></>}

            <SFaTwoEmptyBox />
          </>
        )}

        {isLogin ? (
          <STextContainer>
            {loginError ? <SErroText>{translate(loginError)}</SErroText> : <div />}
            <SForgotPassLink to="../forgetPassword">
              {translate('login_forgot_password')}
            </SForgotPassLink>
          </STextContainer>
        ) : (
          <SLoginEmptyBox />
        )}

        <SButton aria-label="login" disabled={!isValid} type="submit" tx="login_login" />
        <SSSoLink to={isLogin ? '../sso' : '../login'}>
          {isLogin ? translate('login_signOnSso') : translate('login_withCosafe')}
        </SSSoLink>
        <SPrivacyText>
          {translate('login_privacyPolicyNotice')}{' '}
          <a target="_blank" href={translate('login_privacyPolicyUrl')} rel="noreferrer noopener">
            {translate('privacyPolicy')}
          </a>
        </SPrivacyText>
      </SForm>

      {isDevRelease() && (
        <>
          <SButton
            onClick={handleRemoveTestUrl}
            disabled={!isTestApiUrlApplied()}
            text="Reset API_URL"
            $textOnly
          ></SButton>
          <STextField
            placeholder="Enter API_URL for testing"
            onChange={onUrlChange}
            value={url}
            autoCapitalize="none"
            error={false}
          />
          <SButton
            onClick={handleApplyTestUrl}
            disabled={!url}
            text="Apply API_URL"
            $textOnly
          ></SButton>
        </>
      )}
    </SPage>
  );
};
