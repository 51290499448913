import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { EmptyListFallback } from '../../components/EmptyListFallback/EmptyListFallback';
import { Page } from '../../components/Page/Page';

export const SPage = styled(Page)`
  position: relative;
`;

export const SMembersList = styled.div`
  min-height: 0;
  height: calc(100% - 6.25rem);
  padding-bottom: 5rem;
  /* vertical scrolling + hide scrollbar   */
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SMembersTitle = styled.h2`
  margin: 0.25rem 0 0.5rem;
  font-size: 16px;
  font-family: 'Roboto-Regular';
  color: ${palette.silver};
  line-height: 19px;
`;

export const SEmptyListFallback = styled(EmptyListFallback)`
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  p {
    font-family: 'Roboto-Regular';
    font-weight: 400;
  }
`;

export const SColumnsContainers = styled.div`
  cursor: pointer;
  background-color: ${palette.fadedDarkBlue};
  margin-bottom: 0.5rem;
  padding: 1rem;
  border: 1px solid ${palette.queenBlue};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  :last-child {
    margin-bottom: 5rem;
  }
`;

export const SRowContainers = styled.div`
  color: ${palette.white};
  padding: 0 4px 0 12px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SMemberInfoContainer = styled(SRowContainers)`
  width: calc(100% - 40px);
`;

export const SName = styled.span`
  font-size: 16px;
  font-family: 'Roboto-Regular';
  color: ${palette.white};
  line-height: 19px;
  margin-right: 5px;
`;

export const SInfoContainer = styled.div<{ marginTop: boolean }>`
  display: flex;
  align-items: baseline;
  .margin-left {
    align-self: center;
    margin-left: 0.75rem;
  }

  margin-top: ${props => props.marginTop && '4px'};
`;
export const SMemberInfoText = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const SInfo = styled.span`
  font-size: 12px;
  font-family: 'Roboto-Regular';
  color: ${palette.silver};
  line-height: 14px;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;
