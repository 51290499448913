import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GreenCheck } from '../../assets/imgs/chats/green-check.svg';
import AvailabilityChip from '../../components/AvailabilityChip/AvailabilityChip';
import { MessagesRecipients } from '../ChatsList/chatListSlice/types';
import {
  STitlesContainer,
  STitle,
  SSeen,
  SSeenContainer,
  SRecipient,
  SRecipientsList,
  SLine,
  SName,
  Row,
  Column,
} from './ReadReceiptList.styles';
import { getNumbersBasedDateTextByLanguage } from '../../utils/date';
import { ProfilePicture } from '../../components/ProfilePicture/ProfilePicture';

export interface ReadReceiptItem {
  displayName: string;
  read: boolean;
  firstRead?: string | null;
  lastRead?: string | null;
  photoFileName: string | null;
  userID: number;
  title?: string;
  available?: boolean;
}

interface ReadReceiptListProps {
  recipients?: MessagesRecipients[] | ReadReceiptItem[];
  isShowFirstRead?: boolean;
}

export const ReadReceiptList = (props: ReadReceiptListProps) => {
  const { recipients, isShowFirstRead = false } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigateToProfile = (uid: number) => {
    navigate(`memberSettings/${uid}`);
  };

  const sortedRecipients = [...(recipients ?? [])].sort((a, b) => {
    if (a.read !== b.read) {
      return a.read ? -1 : 1;
    }
    return a.displayName.localeCompare(b.displayName);
  });

  return (
    <SRecipientsList role="recipientsList">
      {sortedRecipients.map((recipient, key) => (
        <li key={key} role={`recipientContainer-${recipient.displayName}`}>
          {key !== 0 && <SLine role="line" />}
          <SRecipient
            onClick={() => handleNavigateToProfile(recipient.userID)}
            role="contentContainer"
          >
            <ProfilePicture profilePictureFileName={recipient.photoFileName} />
            <Column role="innerContainer">
              <Row role="recipientInfoContainer" className="alignItemsCenter spaceBetween">
                <STitlesContainer>
                  <SName role="name">{recipient.displayName}</SName>
                  {recipient.title && <STitle role="title">{recipient.title}</STitle>}
                  {recipient.available === false && <AvailabilityChip isAvailable={false} />}
                </STitlesContainer>
                <SSeenContainer role="seenContainer">
                  <SSeen seen={recipient.read} role="seenText">
                    {recipient.read
                      ? getNumbersBasedDateTextByLanguage({
                          dateText: isShowFirstRead ? recipient.firstRead : recipient.lastRead,
                        })
                      : t(`messages_notSeen`)}
                  </SSeen>
                  {recipient.read && (
                    <div className="checkContainer" role="checkContainer">
                      <GreenCheck role="checkIcon1" />
                      <GreenCheck role="checkIcon2" />
                    </div>
                  )}
                </SSeenContainer>
              </Row>
            </Column>
          </SRecipient>
        </li>
      ))}
    </SRecipientsList>
  );
};
