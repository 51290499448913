import styled, { css } from 'styled-components';
import { palette } from '../../../theme/colors';
import { toRem } from '../../../utils/toRem';

export const SMapWrapper = styled.div`
  position: relative;
  padding: ${toRem(4)};
`;

export const STime = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  background-color: ${palette.background};
  height: ${toRem(18)};
  width: ${toRem(38)};
  border-radius: ${toRem(18)};
  font-size: ${toRem(10)};
  font-family: 'Roboto-Medium';
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SAlertContainer = styled.div<any>`
  ${props =>
    !props.isSent &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}

  margin-left: 0.75rem;
`;

export const SAlertMessageInfoContainer = styled.div`
  font-size: 0.75rem;
  font-family: 'Roboto-Regular';
  font-weight: 500;
  .alertDetailsMessageContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0.25rem;
  }

  .alertIconContainer {
    margin-right: 5px;
    img {
      max-height: 3rem;
    }
  }

  .emergencyType {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 16px;
    line-height: ${toRem(20.8)};
    color: ${palette.white};
    margin-bottom: 0.25rem;
  }

  .location {
    color: ${palette.navy20};
    flex-shrink: 1;
  }
  .organization {
    color: ${palette.navy20};
    flex-shrink: 1;
    margin-bottom: 0.1875rem;
  }

  .forwardedAlarmOrganization,
  .forwardedAlarmLocation {
    color: ${palette.navy20};
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: ${toRem(12)};
    line-height: ${toRem(15.3)};
  }
  .text-container {
    max-width: 100%;
  }
`;

export const SNoMapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  place-content: center;

  div {
    position: absolute;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
  }

  img {
    width: 100%;
    height: 100%;
    filter: blur(2px);
    object-fit: cover;
    opacity: 0.5;
  }

  p {
    margin: 0.75rem auto 0;
    text-align: center;
    width: 80%;
  }
`;

export const SCustomTextWrapper = styled.div<{ $haveInstructions?: boolean }>`
  display: flex;
  font-size: 0.875rem;
  padding: ${toRem(6)} ${toRem(10)} ${toRem(6)};
  font-family: 'Roboto-Regular';
  border-top: ${props =>
    props.$haveInstructions && `1px solid ${palette.whiteWithZeroPointZeroEightOpacity}`};
`;

export const SPrewrittenTextWrapper = styled.p`
  padding: 0.5rem 0.75rem 0.375rem;
  padding-top: ;
  min-height: 3rem;

  font-size: 0.875rem;
`;

export const SPrewrittenTextTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0.333rem;
`;

export const SPrewrittenTextTitleText = styled.p`
  padding-left: 0.5rem;
  font-size: 0.75rem;
`;

export const SAlarmText = styled.p`
  font-size: 0.75rem;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 1rem;
  color: ${palette.navy20};
  white-space: pre-wrap;
  padding-right: 0.5rem;
  flex: 1;

  &.instructions {
    font-size: ${toRem(14)};
  }
`;

export const SCustomTextWrapperTime = styled.p`
  align-self: flex-end;
  font-size: ${toRem(11)};
  color: ${palette.navy20};
  font-weight: 500;
  font-family: 'Roboto-Regular';
`;
