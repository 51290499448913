import { palette } from '../../theme/colors';
import { Backdrop } from '../Backdrop/Backdrop';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../ActionButtons/ActionButtons.style';
import {
  SActionBtnWrapper,
  SFilterCheckboxItem,
  SFlexDiv,
  SFilter,
  SHeader,
  SSearchBarBase,
  SLabelContainer,
  SLabel,
  ScrollList,
} from './CreateChatFilter.styles';
import _ from 'lodash';
import { GroupMember } from '../../containers/GroupDetail/groupDetailSlice/types';
import SCheckbox from '../FilterItem/SBoxButton';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { SelectListUser } from '../../containers/CreateMessage/createMessageSlice/types';
import { ResetFiltersButton } from '../Button/ResetFiltersButton';

interface MessageFilterProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onFilter: (selectedMembers: GroupMember[], extraMemberslistSource?: SelectListUser[]) => void;
  label: string;
  title: string;
  searchbarTitle: string;
  items: GroupMember[];
  //! this page need a series refactoring, did this shit dupication because we don't have enough time !!!!
  extraMembers?: SelectListUser[];
  isCoAlert?: boolean;
  allowEmptyFilter?: boolean;
}

export const CreateChatFilter = (props: MessageFilterProps) => {
  const {
    isOpen,
    setIsOpen,
    onFilter,
    items,
    title,
    searchbarTitle,
    extraMembers,
    isCoAlert,
    allowEmptyFilter = false,
  } = props;
  const [searchText, setSearchText] = useState('');
  const [memberState, setMemberState] = useState(items);
  const [extraMembersState, setextraMembersState] = useState(extraMembers);

  const confirm = useConfirmation();
  useEffect(() => {
    if (!isOpen) {
      setMemberState(items);
      setextraMembersState(extraMembers);
      setSearchText('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, items]);

  const onSearch = (text: string) => {
    setSearchText(text);
  };

  const selectAll = (allChecked: boolean, shouldClose = false) => {
    let newState;
    let newExtraState;
    if (allChecked) {
      newState = memberState.map(member => ({
        ...member,
        isSelected: false,
      }));
      newExtraState = extraMembersState?.map(member => ({
        ...member,
        isSelected: false,
      }));
    } else {
      newState = memberState.map(member => ({ ...member, isSelected: true }));
      newExtraState = extraMembersState?.map(member => ({
        ...member,
        isSelected: true,
      }));
    }
    setMemberState(newState);
    setextraMembersState(newExtraState);
    if (shouldClose) {
      onFilter(newState, newExtraState);
    }
  };

  const handleCheckMember = (id: number) => {
    const selectedMembers = [...memberState];
    const found = _.findIndex(selectedMembers, member => member.userID === id);
    if (found > -1) {
      const newMember = {
        ...selectedMembers[found],
        isSelected: !selectedMembers[found].isSelected,
      };
      selectedMembers[found] = newMember;
      setMemberState(selectedMembers);
    }
  };
  const handleCheckExtraMember = (id: number) => {
    const selectedMembers = [...(extraMembersState ?? [])];
    const found = _.findIndex(selectedMembers, member => member.id === id);
    if (found > -1) {
      const newMember = {
        ...selectedMembers[found],
        isSelected: !selectedMembers[found].isSelected,
      };
      selectedMembers[found] = newMember;
      setextraMembersState(selectedMembers);
    }
  };

  const isListEmpty = () => {
    if (
      _.isEmpty(_.filter(memberState, x => x.isSelected)) &&
      _.isEmpty(_.filter(extraMembersState, x => x.isSelected))
    ) {
      confirm({
        title: 'warning',
        description: 'message_create_selectAtLeastOneGroup',
        onSubmit: () => {},
        confirmText: 'ok',
      });
      return true;
    }
    return false;
  };

  const handleFilter = () => {
    if (!isCoAlert && !allowEmptyFilter && isListEmpty()) return;

    onFilter(memberState, extraMembersState);
  };

  const handleReset = () => {
    selectAll(false, true);
  };

  const changeSelect = (id: number) => {
    handleCheckMember(id);
  };
  const changeExtraSelect = (id: number) => {
    handleCheckExtraMember(id);
  };

  const groupMembersFiltred = _.filter(memberState, x =>
    _.includes(_.toLower(x.userName), _.toLower(searchText))
  );
  const groupExtraMembersFiltred = _.filter(extraMembersState, x =>
    _.includes(_.toLower(x.displayName), _.toLower(searchText))
  );

  const allChecked =
    _.isEmpty(_.filter(groupMembersFiltred, x => !x.isSelected)) &&
    _.isEmpty(_.filter(groupExtraMembersFiltred, x => !x.isSelected));

  const { t } = useTranslation();

  if (!isOpen) return <></>;

  return (
    <>
      <SFilter role="createChatFilter">
        <SHeader role="header">
          <rect width="400" height="100" fill={palette.silver} />
        </SHeader>
        <SSearchBarBase
          placeholderTx="documents_search"
          placeholder="Search..."
          fallback={onSearch}
          label={t(`${searchbarTitle}`)}
          value={searchText}
        />
        <SLabelContainer
          onClick={() => selectAll(allChecked)}
          $disable={!_.isEmpty(searchText)}
          role="selectAllContainer"
        >
          <SLabel role="title">{t(`${title}`)}</SLabel>
          <SCheckbox isChecked={allChecked} />
        </SLabelContainer>
        <ScrollList role="itemsList">
          {groupExtraMembersFiltred.map((member, key) => (
            <div key={key} role={`memberItem-${member.id}`}>
              <SFilterCheckboxItem
                checked={member.isSelected ? true : false}
                name={member.displayName}
                setSelected={() => changeExtraSelect(member.id)}
                key={key}
                id={member.id}
                type="box"
                hasImage
                image={member?.photoFileName}
                showAvailability={member.available === false}
                searchTerm={searchText}
              />
            </div>
          ))}
          {groupMembersFiltred.map((member, key) => (
            <div key={key} role={`memberItem-${member.userID}`}>
              <SFilterCheckboxItem
                checked={member.isSelected ? true : false}
                name={member.userName}
                setSelected={() => changeSelect(member.userID)}
                key={key}
                id={member.userID}
                type="box"
                hasImage
                image={member?.photoFileName}
                showAvailability={member.available === false}
              />
            </div>
          ))}
        </ScrollList>
        <SFlexDiv />
        <SActionBtnWrapper>
          <ResetFiltersButton
            marginBottom="0.8125rem"
            isDisplayed={!allChecked}
            onClick={handleReset}
          />
          <ActionButton type="button" tx={props.label} key={props.label} onClick={handleFilter} />
        </SActionBtnWrapper>
      </SFilter>
      <Backdrop setModal={setIsOpen} />
    </>
  );
};
