import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SOrganization = styled.p`
  margin-top: ${toRem(3.5)};
  padding-left: 0.5rem;
  color: ${palette.silver};
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;
