import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EmptyDocuments from '../../assets/imgs/NotFound/no-result.svg';
import { DocumentItem } from '../../components/DocumentItem/DocumentItem';
import Loader from '../../components/Loader/Loader';
import { FileItem, FolderItem } from '../../containers/Documents/documentsSlice/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { translate } from '../../utils/translate';
import {
  selectDocumentGroupById,
  selectDocumentsIsListLoading,
  selectFiles,
  selectFilesFoldersSorted,
  selectFolders,
  updateFav,
} from '../Documents/documentsSlice';
import { fetchFolders } from '../Documents/documentsSlice/actionCreators';

import NewDocIcon from '../../assets/imgs/documents/new-document.svg';
import NewFolderIcon from '../../assets/imgs/documents/new-folder.svg';
import { FilesAndFoldersOptions } from '../../components/FilesAndFoldersOptions/FilesAndFoldersOptions';
import { OptionItemProps } from '../../components/Options/Options';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { getOnFavChangeAlertTx, isDocumentOffline } from '../Documents/helpers';
import {
  SEmptyListFallback,
  SFloatingDocumentAddButton,
  SHiddenInput,
  SOptions,
} from '../Documents/styles';
import { fetchAddDocumentFolders } from '../EditDocuments/EditDocumentSlice/actionCreators';
import { handleSubmissions } from '../EditDocuments/EditDocuments';
import { selectUser } from '../Login/LoginSlice';
import { getCurrentUserById } from '../Login/LoginSlice/actionCreators';
import { generateOptions, getFileOrFolderInfoById } from './BrowseFolder.helpers';
import { SList, SPage, SSearchFilterBar } from './BrowseFolder.styles';
import { toggleFavoriteStatusWithFallback } from '../../apis/mediaAPI';
import { AlertBanner, AlertBannerRef } from '../../components/AlertBanner/AlertBanner';
import {
  DocumentSortingType,
  DocumentsSortingOptions,
} from '../../components/DocumentsSortingOptions/DocumentsSortingOptions';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { getNumbersBasedDateTextByLanguage } from '../../utils/date';

export interface SelectedDocument {
  id: number;
  type: 'FILE' | 'FOLDER';
}
export interface BrowseFolderOptionsStateType {
  isOpen: boolean;
  options?: OptionItemProps[];
}

export const BrowseFolder = () => {
  const inputFile = useRef<HTMLInputElement>(null);
  const formData = new FormData();

  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [sortBy, setSortBy] = useState<DocumentSortingType>(DocumentSortingType.NewToOld);

  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const confirm = useConfirmation();
  const user = useAppSelector(selectUser);
  const isDocumentsManager = user?.roles?.includes('DocumentsManager');

  const { folderId, groupDocumentId } = useParams();

  const isFolderContentList = !!folderId;

  const groupDocumentIdNumber = Number(groupDocumentId);
  const folderIdNumber = Number(folderId);

  // Data sources START ====== ====== ====== ====== ====== ======

  const allFolders = useAppSelector(selectFolders);
  const allFiles = useAppSelector(selectFiles);
  const groupFolder = useAppSelector(selectDocumentGroupById(groupDocumentIdNumber));

  const {
    folders,
    files,
    sortedFolderFiles: itemsList,
  } = useAppSelector(
    selectFilesFoldersSorted({
      groupId: groupDocumentIdNumber,
      folderId: folderIdNumber,
      searchTerm,
      sortBy,
    })
  );
  const isLoading = useAppSelector(selectDocumentsIsListLoading);
  const layout = useLayoutContext();

  // Data sources END ====== ====== ====== ====== ====== ======

  useEffect(() => {
    if (!user) dispatch(getCurrentUserById());
    dispatch(fetchFolders());
  }, []);

  const onSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleDocumentRedirect = (id: number) => {
    if (!isFolderContentList) navigation(`${id}`);
  };

  const onSort = (_sorting: DocumentSortingType) => {
    setSortBy(_sorting);
  };

  // OPTIONS MANAGEMENT START ================================================================
  const alertRef = useRef<AlertBannerRef>(null);

  const [selected, setSelected] = useState<SelectedDocument | undefined>(undefined);
  const [optionsState, setOptionsState] = useState<BrowseFolderOptionsStateType>({
    isOpen: false,
  });

  const setOptionsOpen = (isOpen: boolean) => {
    setOptionsState(prev => ({ ...prev, isOpen }));
  };

  useEffect(() => {
    layout.setTabsState(!optionsState.isOpen);
  }, [optionsState]);

  const handleMoreOptionClick = (type: 'FILE' | 'FOLDER') => (id: number) => {
    setSelected({ id, type });
    setOptionsOpen(true);

    generateOptions({
      dependencies: {
        confirm,
        navigation,
        setOptionsState,
        groupFolderId: groupDocumentIdNumber,
        browseFolderId: folderIdNumber,
        dispatch,
      },
      files,
      folders,
      selected: { id, type },
      isDocumentsManager: !!isDocumentsManager,
    });
  };

  const handleFavClick = async (_id: number, newFavValue: boolean) => {
    toggleFavoriteStatusWithFallback({
      id: _id,
      newStatus: newFavValue,
      onFallback: (__id, revertedValue) => {
        dispatch(updateFav({ id: __id, newFavValue: revertedValue }));
      },
      onSuccess: () => {
        alertRef.current?.alert(translate(getOnFavChangeAlertTx(newFavValue, false)));
      },
    });
    dispatch(updateFav({ id: _id, newFavValue }));
  };

  const handleCreateFolder = (text: string | undefined) => {
    navigation(`/documents/edit/createFolder/${text}`);
  };

  const onAddDocumentClick = () => inputFile.current?.click();
  const onAddFolderClick = () => {
    confirm({
      title: 'documents_name_folder',
      placeholderTx: 'documents_folder_name',
      onSubmit: text => handleCreateFolder(text),
      onCancel: () => {},
      confirmText: 'messages_proceed',
      inputBox: true,
      confirmStyle: 'standard',
    });
  };

  const openAddModal = (isWithFolder = true as boolean) => {
    const callbackWrapper = (addItemF: () => void) => {
      layout.setTabsState(true);
      setOptionsState({ isOpen: false });
      addItemF();
    };
    const options = isWithFolder
      ? [
          {
            name: 'documents_add_new_doc',
            icon: NewDocIcon,
            callback: () => callbackWrapper(onAddDocumentClick),
          },
          {
            name: 'documents_add_new_folder',
            icon: NewFolderIcon,
            callback: () => callbackWrapper(onAddFolderClick),
          },
        ]
      : [
          {
            name: 'documents_add_new_doc',
            icon: NewDocIcon,
            callback: () => callbackWrapper(onAddDocumentClick),
          },
        ];

    setSelected(undefined); // There's no bulk edit here -- only One Item for 'selected'. So it's safe to clear it here, since this modals can't be opened both at the same time and keep selection.
    // And for handleMoreOptionClick, it's still safe to clear 'selected', because 'fileId' in this case -- is passing From DocumentItem
    setOptionsState({
      isOpen: true,
      options,
    });
  };

  useEffect(() => {
    return () => {
      setOptionsState({
        isOpen: false,
      });
    };
  }, []);

  // OPTIONS MANAGEMENT END ================================================================

  // creating folder & adding documents functionality

  const handleSubmission = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      formData.append('filePDF', file);
      dispatch(fetchAddDocumentFolders([folderIdNumber], formData));
    }
  };

  const handleInputChange = (multi?: boolean) => (event: ChangeEvent<HTMLInputElement>) => {
    if (multi) {
      handleSubmissions(event);
      navigation('/documents/edit/addDocument');
      return;
    }
    handleSubmission(event);
  };

  const getElementFromItem = (item: FileItem | FolderItem, index: number) => {
    if ((item as FolderItem).GroupID) {
      const folderItem = item as FolderItem;
      return (
        <DocumentItem
          key={`${folderItem.ID}-${index}`}
          iconVariant={folderItem.Offline ? 'OFFLINE_FOLDER' : 'FOLDER'}
          title={folderItem.Name}
          onCLick={handleDocumentRedirect}
          itemId={folderItem.ID}
          onMoreOptionClick={handleMoreOptionClick('FOLDER')}
          favorite={folderItem.favorited}
          searchTerm={searchTerm}
        />
      );
    } else {
      const fileItem = item as FileItem;
      return (
        <DocumentItem
          key={`${fileItem.id}-${index}`}
          iconVariant={
            isDocumentOffline(fileItem, groupDocumentIdNumber, folderIdNumber)
              ? 'OFFLINE_FILE'
              : 'FILE'
          }
          title={fileItem.original_filen_name}
          pdfLink={fileItem.document_url}
          subTitle={`${translate('updated_at')} ${getNumbersBasedDateTextByLanguage({
            dateText: fileItem.upload_time,
          })}`}
          onCLick={handleDocumentRedirect}
          itemId={fileItem.id}
          onMoreOptionClick={handleMoreOptionClick('FILE')}
          onFavClick={handleFavClick}
          favorite={fileItem.favorite}
          searchTerm={searchTerm}
        />
      );
    }
  };
  const isListEmpty = itemsList.length === 0;
  if (isLoading) return <Loader />;

  return (
    <SPage>
      <AlertBanner ref={alertRef} alertTopToValue={16} placeHolderHeight={48} />
      <SSearchFilterBar onSearch={onSearch} value={searchTerm || ''} withoutFilterButton />
      <DocumentsSortingOptions onSort={onSort} initialSort={sortBy} />

      <SEmptyListFallback
        src={EmptyDocuments}
        listLength={itemsList.length}
        isLoading={isLoading}
        searchTerm={searchTerm}
        $searchTerm={!!searchTerm}
        emptyListTx={'documents_empty_list'}
        noSearchTx={'document-empty-search'}
        ignoreSearchTermInFallBackText
      />

      {!isListEmpty && (
        <SList $bottomPad={isDocumentsManager}>
          {itemsList.map((item, index) => getElementFromItem(item, index))}
        </SList>
      )}
      {selected && optionsState.options && groupFolder && (
        <FilesAndFoldersOptions
          items={optionsState.options}
          isOpen={optionsState.isOpen}
          setIsOpen={setOptionsOpen}
          withoutOptions={!isDocumentsManager}
          setTabBar
          info={getFileOrFolderInfoById(
            allFiles,
            allFolders,
            groupFolder,
            selected,
            groupDocumentIdNumber,
            folderIdNumber
          )}
        />
      )}
      {!selected && optionsState.options && (
        <SOptions
          items={optionsState.options}
          isOpen={optionsState.isOpen}
          setIsOpen={setOptionsOpen}
          setTabBar
        />
      )}

      {!isFolderContentList && isDocumentsManager && (
        <>
          <SFloatingDocumentAddButton onClick={() => openAddModal(true)} />
          {/* do we need the two inputs ?? */}
          <SHiddenInput
            type="file"
            id="file"
            accept=".pdf"
            ref={inputFile}
            onChange={handleInputChange(true)}
          />
        </>
      )}
      {isFolderContentList && isDocumentsManager && (
        <>
          <SFloatingDocumentAddButton onClick={() => openAddModal(false)} />
          {/* do we need the two inputs ?? */}
          <SHiddenInput
            type="file"
            id="file"
            accept=".pdf"
            ref={inputFile}
            onChange={handleInputChange(false)}
          />
        </>
      )}
    </SPage>
  );
};
