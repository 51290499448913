import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import {
  DocumentFilterV2,
  DocumentFiltersV2,
} from '../../components/DocumentFilter/DocumentFilterV2';
import { DocumentItem } from '../../components/DocumentItem/DocumentItem';
import Loader from '../../components/Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  removeShallowFavorites,
  selectDocumentGroupsWithFilter,
  selectDocumentsIsListLoading,
  selectFiles,
  selectFilesFoldersSortedFiltered,
  updateFav,
} from './documentsSlice';
import { fetchFolders } from './documentsSlice/actionCreators';

import { useNavigate } from 'react-router-dom';
import EmptyDocuments from '../../assets/imgs/NotFound/no-result.svg';
import { ReactComponent as EmptyStar } from '../../assets/imgs/documents/empty-star-small.svg';
import { OptionItemProps } from '../../components/Options/Options';
import { selectUser } from '../Login/LoginSlice';
import { getCurrentUserById } from '../Login/LoginSlice/actionCreators';
import {
  getGroupsOrganizedByAccount,
  getOnFavChangeAlertTx,
  isAtLeastOneInstanceFileOffline,
} from './helpers';

import NewDocIcon from '../../assets/imgs/documents/new-document.svg';
import NewFolderIcon from '../../assets/imgs/documents/new-folder.svg';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import {
  LocalStorageFiltersKeys,
  checkIfFiltersApplied,
  useLocalStorageFilters,
} from '../../utils/customHooks/useLocalStorageFilters';
import { handleSubmissions } from '../EditDocuments/EditDocuments';
import {
  SEmptyListFallback,
  SFloatingDocumentAddButton,
  SHiddenInput,
  SList,
  SOptions,
  SPage,
  SSearchFilterBar,
  SSlider,
  STab,
  STabContainer,
  STabList,
  STabPanel,
  STabs,
} from './styles';
// import { STabList } from '../CreateMessage/CreateMessageList';
import { toggleFavoriteStatusWithFallback } from '../../apis/mediaAPI';
import { AlertBanner, AlertBannerRef } from '../../components/AlertBanner/AlertBanner';
import { generateInitialSelectedData } from '../../components/DocumentFilter/helpers';
import {
  DocumentSortingType,
  DocumentsSortingOptions,
} from '../../components/DocumentsSortingOptions/DocumentsSortingOptions';
import { FilesAndFoldersOptions } from '../../components/FilesAndFoldersOptions/FilesAndFoldersOptions';
import {
  OperationListSelectModal,
  OperationListSelectModalRef,
} from '../../components/OperationListSelectModal/OperationListSelectModal';
import { translate } from '../../utils/translate';
import { BrowseFolderOptionsStateType } from '../BrowseFolder/BrowseFolder';
import { generateOptionsV2, getFileOrFolderInfoByIdV2 } from '../BrowseFolder/BrowseFolder.helpers';
import { DocumentGroup, FileItem, FolderItem } from './documentsSlice/types';
import { getNumbersBasedDateTextByLanguage } from '../../utils/date';

export const generateDocumentDefaultFilters = (
  isSeeOrgGroups: boolean | undefined
): DocumentFiltersV2 => {
  return {
    showEmptyFolders: false,
    showFilesOnly: false,
    selectedData: 'UNSELECTED_ALL',
    memberFilter: isSeeOrgGroups ? ['MEMBER'] : undefined,
  };
};

type DocumentType = 'document' | 'folder' | 'document_vertical_date';

export interface Document {
  id: number;
  name: string;
  group: string;
  count: number;
  varient: DocumentType;
}
export interface DocumentFiles extends Omit<Document, 'varient' | 'group' | 'count'> {
  varient: 'document_vertical_date';
  size: string;
  date: string;
}

export interface IDocumentsProps {
  isHeaderOptionsOpen: boolean;
  setHeaderOptionsOpen: Dispatch<SetStateAction<boolean>>;
}

enum SelectedTab {
  Documents = 'documents',
  Favorites = 'favorites',
}

export type EnhancedFolderItem = FolderItem & { name: string; id: number; subName?: string };

export const Documents = (props: IDocumentsProps) => {
  const { isHeaderOptionsOpen, setHeaderOptionsOpen } = props;

  const inputFile = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<SelectedTab>(SelectedTab.Documents);
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const confirm = useConfirmation();

  const user = useAppSelector(selectUser);

  const isSeeOrgGroups = user?.roles?.includes('SeeOrgGroups');
  const isDocumentsManager = user?.roles?.includes('DocumentsManager');

  const defaultFilters = useMemo(() => generateDocumentDefaultFilters(isSeeOrgGroups), []);

  const [filters, setFilters] = useLocalStorageFilters<DocumentFiltersV2>(
    LocalStorageFiltersKeys.documents,
    { ...defaultFilters }
  );
  const [stagedFilter, setStagedFilters] = useState<DocumentFiltersV2>({ ...filters });

  const [sorting, setSorting] = useLocalStorageFilters<{ selected: DocumentSortingType }>(
    LocalStorageFiltersKeys.documentsSorting,
    { selected: DocumentSortingType.NewToOld }
  );
  const { selected: sortBy } = sorting;
  const setSortBy = (_sorting: DocumentSortingType) => {
    setSorting({ selected: _sorting });
  };

  // experiment with pure functions
  const documentGroups = useAppSelector(selectDocumentGroupsWithFilter(stagedFilter));
  const allFiles = useAppSelector(selectFiles);

  const { itemsList } = useAppSelector(
    selectFilesFoldersSortedFiltered({
      sortBy,
      searchTerm,
      filters: filters,
      showDocumentsFromFolders: activeTab === SelectedTab.Favorites,
      groups: filters.memberFilter?.length === 1 ? documentGroups : undefined,
    })
  );
  const isLoading = useAppSelector(selectDocumentsIsListLoading);

  useEffect(() => {
    if (!user) dispatch(getCurrentUserById());
    dispatch(fetchFolders());
    // do we need this performance guard ?
    // if (documentGroups.length === 0) dispatch(fetchFolders());
  }, []);

  useEffect(() => {
    if (activeTab === SelectedTab.Documents) {
      dispatch(removeShallowFavorites());
    }
  }, [activeTab]);

  // OPTIONS MANAGMENT START ================================================================
  const operationExtraIdsSelectRef =
    useRef<OperationListSelectModalRef<DocumentGroup | EnhancedFolderItem>>(null);
  const [selected, setSelected] = useState<FileItem | FolderItem>();
  const [optionsState, setOptionsState] = useState<
    BrowseFolderOptionsStateType & { isHeaderOptionOpen?: boolean }
  >({
    isOpen: false,
    isHeaderOptionOpen: false,
  });
  const setOptionsOpen = (isOpen: boolean) => {
    setOptionsState(prev => ({ ...prev, isOpen }));
    // setHeaderOptionsOpen(isOpen);
  };
  const setIsHeaderOptionsOpen = (_isHeaderOptionOpen: boolean) => {
    setOptionsState(prev => ({ ...prev, isHeaderOptionOpen: _isHeaderOptionOpen }));
  };

  // header options start
  const handleManageOffline = () => {
    navigation('edit/manageOffline');
    setHeaderOptionsOpen(false);
  };
  useEffect(() => {
    if (isHeaderOptionsOpen) {
      setOptionsState(prev => ({
        ...prev,
        isHeaderOptionOpen: true,
        options: [
          {
            name: 'documents_manage_offline_files',
            callback: handleManageOffline,
          },
        ],
      }));
    }
  }, [isHeaderOptionsOpen]);

  useEffect(() => {
    return () => {
      setOptionsState({
        isOpen: false,
      });
    };
  }, []);
  // header options end

  const handleMoreOptionClick = (item: FolderItem | FileItem) => () => {
    setSelected(item);
    generateOptionsV2({
      dependencies: {
        confirm,
        dispatch,
        navigation,
        setOptionsState,
        extraOperationRef: operationExtraIdsSelectRef,
      },
      isDocumentsManager: !!isDocumentsManager,
      selected: item,
    });
  };

  const handleCreateFolder = (text: string | undefined) => {
    navigation(`edit/createFolder/${text}`);
  };
  const onAddDocumentClick = () => inputFile.current?.click();
  const onAddFolderClick = () => {
    confirm({
      title: 'documents_name_folder',
      onSubmit: text => {
        handleCreateFolder(text);
      },
      onCancel: () => {},
      confirmText: 'messages_proceed',
      inputBox: true,
      placeholderTx: 'documents_folder_name',
      confirmStyle: 'standard',
    });
  };

  // OPTIONS MANAGMENT END ================================================================
  const toggleFilterOpen = () => setFilterOpen(prev => !prev);

  const openAddModal = () => {
    const callbackWrapper = (addItemF: () => void) => {
      setOptionsState(prev => ({ ...prev, isHeaderOptionOpen: false }));
      addItemF();
    };

    setOptionsState(prev => ({
      ...prev,
      isHeaderOptionOpen: true,
      options: [
        {
          name: 'documents_add_new_doc',
          icon: NewDocIcon,
          callback: () => callbackWrapper(onAddDocumentClick),
        },
        {
          name: 'documents_add_new_folder',
          icon: NewFolderIcon,
          callback: () => callbackWrapper(onAddFolderClick),
        },
      ] as OptionItemProps[],
    }));
  };

  // OPTIONS MANAGMENT END ================================================================

  const alertRef = useRef<AlertBannerRef>(null);

  const onFilter = (_filters: DocumentFiltersV2) => {
    setFilters(_filters);
    setStagedFilters(_filters);

    setFilterOpen(false);
  };

  const onChange = (_filters: DocumentFiltersV2) => {
    setStagedFilters(prev => ({
      ...prev,
      memberFilter: _filters.memberFilter,
    }));
  };
  const resetStaged = () => {
    setStagedFilters({
      memberFilter: filters.memberFilter,
      showEmptyFolders: filters.showEmptyFolders,
      selectedData: filters.selectedData,
      showFilesOnly: filters.showFilesOnly,
    });
  };
  const onSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleFolderRedirect = (groupID: number) => (id: number) => navigation(`${groupID}/${id}`);

  const handleFavClick = async (_id: number, newFavValue: boolean, isFolder = false) => {
    toggleFavoriteStatusWithFallback({
      id: _id,
      newStatus: newFavValue,
      onFallback: (__id, revertedValue, _isFolder) => {
        dispatch(
          updateFav({
            id: __id,
            newFavValue: revertedValue,
            isFolder: _isFolder,
            isFallbackUpdate: true,
          })
        );
      },
      onSuccess: () => {
        alertRef.current?.alert(translate(getOnFavChangeAlertTx(newFavValue, isFolder)));
      },
      isFolder,
    });
    dispatch(updateFav({ id: _id, newFavValue, isFolder }));
  };

  const handleInputChange = (event: any) => {
    handleSubmissions(event);
    navigation('edit/addDocument');
  };

  const renderFolderOrFile = (item: FileItem | FolderItem, index: number) => {
    if ((item as FolderItem).GroupID) {
      const folderItem = item as FolderItem;
      return (
        <DocumentItem
          key={`${folderItem.ID}-${index}`}
          iconVariant={folderItem.Offline ? 'OFFLINE_FOLDER' : 'FOLDER'}
          title={folderItem.Name}
          onCLick={handleFolderRedirect(folderItem.GroupID)}
          itemId={folderItem.ID}
          subTitle={folderItem.groupName}
          onMoreOptionClick={handleMoreOptionClick(item)}
          favorite={folderItem.favorited}
          onFavClick={(_id, newValue) => handleFavClick(_id, newValue, true)}
          searchTerm={searchTerm}
        />
      );
    } else {
      const fileItem = item as FileItem;

      return (
        <DocumentItem
          key={`${fileItem.id}-${index}`}
          iconVariant={isAtLeastOneInstanceFileOffline(fileItem) ? 'OFFLINE_FILE' : 'FILE'}
          title={fileItem.original_filen_name}
          pdfLink={fileItem.document_url}
          subTitle={`${translate('updated_at')} ${getNumbersBasedDateTextByLanguage({
            dateText: fileItem.upload_time,
          })}`}
          itemId={fileItem.id}
          onMoreOptionClick={handleMoreOptionClick(item)}
          onFavClick={handleFavClick}
          favorite={fileItem.favorite}
          searchTerm={searchTerm}
        />
      );
    }
  };
  const initialMemberFilter = useMemo(
    () => (isSeeOrgGroups ? new Set(filters.memberFilter) : undefined),
    [filters.memberFilter, isSeeOrgGroups]
  );

  // renders
  if (isLoading) return <Loader />;

  return (
    <SPage>
      <STabs
        selectedTabClassName="is-selected"
        selectedTabPanelClassName="is-selected"
        selectedIndex={tabIndex}
        onSelect={index => setTabIndex(index)}
        role="documentsTabs"
      >
        <STabContainer role="tabsContainer">
          <STabList $selectedIndex={tabIndex}>
            <STab onClick={() => setActiveTab(SelectedTab.Documents)}>
              {translate('documents_all_files')}
            </STab>
            <STab onClick={() => setActiveTab(SelectedTab.Favorites)}>
              {
                <>
                  <div className="svgContainer" role="iconContainer">
                    <EmptyStar className="starSVG" role="icon" />
                  </div>
                  <p role="favoritesText">{translate('documents_favorites')}</p>
                </>
              }
            </STab>
          </STabList>
          <SSlider className="slider" role="slider">
            <div className="indicator" role="sliderIndicator" />
          </SSlider>
        </STabContainer>
        <AlertBanner ref={alertRef} />
        <SSearchFilterBar
          onSearch={onSearch}
          handleOpenFilter={toggleFilterOpen}
          withoutFilterButton={!isDocumentsManager}
          isFilterApplied={checkIfFiltersApplied({
            defaultFilters: generateDocumentDefaultFilters(isSeeOrgGroups),
            filters,
          })}
          value={searchTerm}
        />

        <DocumentFilterV2
          defaultFilters={defaultFilters}
          data={documentGroups}
          displayData={getGroupsOrganizedByAccount(documentGroups)}
          isOpen={filterOpen}
          setIsOpen={setFilterOpen}
          onFilter={onFilter}
          onChange={onChange}
          resetStaged={resetStaged}
          initialShowFilesOnly={filters.showFilesOnly}
          initialShowEmptyFolders={filters.showEmptyFolders}
          initialMemberFilter={initialMemberFilter}
          initialSelectedData={generateInitialSelectedData(filters.selectedData, documentGroups)}
        />
        <DocumentsSortingOptions onSort={setSortBy} initialSort={sortBy} />
        <SEmptyListFallback
          src={EmptyDocuments}
          listLength={itemsList.length}
          isLoading={isLoading}
          searchTerm={searchTerm}
          $searchTerm={!!searchTerm}
          emptyListTx={'documents_empty_list'}
          noSearchTx={'document-empty-search'}
          ignoreSearchTermInFallBackText
        />
        <STabPanel>
          {itemsList.length !== 0 && (
            <SList role="documentsList">
              {itemsList.map((folderOrFile, index) => renderFolderOrFile(folderOrFile, index))}
            </SList>
          )}
        </STabPanel>
        <STabPanel>
          {itemsList.length !== 0 && (
            <SList role="favoritesList">
              {itemsList
                .filter((folderOrFile: FolderItem | FileItem) => {
                  if ((folderOrFile as FolderItem).GroupID) {
                    return (folderOrFile as FolderItem).favorited || folderOrFile.shallowFavorite;
                  } else {
                    return (folderOrFile as FileItem).favorite || folderOrFile.shallowFavorite;
                  }
                })
                .map((folderOrFile, index) => renderFolderOrFile(folderOrFile, index))}
            </SList>
          )}
        </STabPanel>
      </STabs>

      {selected && optionsState.options && (
        <FilesAndFoldersOptions
          items={optionsState.options}
          isOpen={optionsState.isOpen}
          setIsOpen={setOptionsOpen}
          withoutOptions={!isDocumentsManager}
          setTabBar
          info={getFileOrFolderInfoByIdV2(allFiles, selected)}
        />
      )}
      {isDocumentsManager && optionsState.options && (
        <SOptions
          items={optionsState.options}
          isOpen={!!optionsState.isHeaderOptionOpen}
          setIsOpen={setIsHeaderOptionsOpen}
          setTabBar
        />
      )}
      {isDocumentsManager && !optionsState.isOpen && (
        <SFloatingDocumentAddButton onClick={openAddModal} />
      )}
      {/* adding document input  */}
      <SHiddenInput
        type="file"
        id="file"
        accept=".pdf"
        ref={inputFile}
        onChange={handleInputChange}
      />
      <OperationListSelectModal ref={operationExtraIdsSelectRef} />
    </SPage>
  );
};
