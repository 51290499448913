/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/naming-convention */

import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  SButton,
  SCheckBoxWithSubTitle,
  SDescription,
  SDrawerModal,
  SList,
  SSeparator,
  STitle,
} from './OperationListSelectModal.styles';
import { DataSelectableListType } from '../DocumentFilter/DocumentFilterV2';
import { useSelectlist } from '../../utils/customHooks/useSelectList';
import { ReactComponent as Folder } from '../../assets/imgs/documents/document-folder.svg';
import { translate } from '../../utils/translate';

// ID to differentiate folders from groups, folders should have the ID + id
type OperationDataSelectableListType = DataSelectableListType & { subName?: string; ID?: number };
type OptionsPresetsType = 'rename' | 'delete' | 'enableOffline' | 'disableOffline';
export interface IOperationListSelectModalProps<T> {}
export interface OperationOpenParams<T> {
  foldersData: T[];
  groupsData: T[];
  preset: OptionsPresetsType;
  onFinish: (folderIds: number[], groupIds: number[]) => void;
}

export interface OperationListSelectModalRef<T> {
  open: (props: OperationOpenParams<T>) => void;
}

const mapOptionsTitle: Record<OptionsPresetsType, string> = {
  rename: 'documents_rename',
  delete: 'document_delete',
  enableOffline: 'documents_enable_offline',
  disableOffline: 'documents_unset_offline_status',
};
const mapOptionsDescription: Record<OptionsPresetsType, string> = {
  rename: 'documents_rename_duplicates_description',
  delete: 'document_delete_duplicates_description',
  enableOffline: 'documents_enable_offline_duplicates_description',
  disableOffline: 'documents_disable_offline_duplicates_description',
};

const OperationModal = <T extends OperationDataSelectableListType>(
  props: IOperationListSelectModalProps<T> | undefined,
  ref: ForwardedRef<OperationListSelectModalRef<T>>
) => {
  const [isOpen, setIsOpen] = useState(false);

  // maybe we don't need generics anymore, need to rethink about it later
  const [foldersData, setFoldersData] = useState<T[] | undefined>(undefined);
  const [groupsData, setGroupsData] = useState<T[] | undefined>(undefined);

  const typeRef = useRef<OptionsPresetsType>();
  const onFinishRef = useRef<(folderIds: number[], groupIds: number[]) => void>();
  const type = typeRef.current;
  const onFinish = onFinishRef.current;

  const open = (_props: OperationOpenParams<T>) => {
    const {
      groupsData: _groupsData,
      foldersData: _foldersData,
      onFinish: _onFinish,
      preset: _type,
    } = _props;
    typeRef.current = _type;
    onFinishRef.current = _onFinish;
    setFoldersData(_foldersData);
    setGroupsData(_groupsData);
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    setFoldersData(undefined);
    setGroupsData(undefined);
    typeRef.current = undefined;
    onFinishRef.current = undefined;
  };

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    []
  );

  useEffect(() => {
    return () => {
      close();
    };
  }, []);

  const {
    handleSelect,
    isStagedSelectedAll,
    stagedSelectedItems,
    onFinishSelecting,
    setStagedSelectedItems,
  } = useSelectlist({ data: foldersData ?? [], multiStage: true });

  const {
    handleSelect: handleSelectGroup,
    isStagedSelectedAll: isStagedSelectedAllGroups,
    stagedSelectedItems: stagedSelectedGroups,
    onFinishSelecting: onFinishSelectingGroups,
    setStagedSelectedItems: setStagedSelectedGroups,
  } = useSelectlist({ data: groupsData ?? [], multiStage: true });

  const handleFinish = () => {
    if (onFinish)
      onFinish(
        Array.from(stagedSelectedItems) as number[],
        Array.from(stagedSelectedGroups) as number[]
      );
    onFinishSelecting(false);
    onFinishSelectingGroups(false);
    close();
  };

  const handleCancel = () => {
    onFinishSelecting(false);
    onFinishSelectingGroups(false);
    close();
  };

  const isGroupsOnly = foldersData?.length === 0;
  const isFoldersOnly = groupsData?.length === 0;
  return (
    <SDrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={close}>
      <SList>
        <STitle>{translate(mapOptionsTitle[type!])}</STitle>
        <SDescription>{translate(mapOptionsDescription[type!])}</SDescription>
        <SCheckBoxWithSubTitle
          $lessPadding
          selected={
            isGroupsOnly
              ? isStagedSelectedAllGroups
              : isFoldersOnly
              ? isStagedSelectedAll
              : isStagedSelectedAll && isStagedSelectedAllGroups
          }
          title={translate('select_all')}
          onToggleCheck={() => {
            if (stagedSelectedGroups.size !== 0 || stagedSelectedItems.size !== 0) {
              setStagedSelectedGroups(new Set());
              setStagedSelectedItems(new Set());
            } else {
              handleSelect();
              handleSelectGroup();
            }
          }}
          valueId={0}
          checkBoxType={'box'}
          withoutSeparator
          clickAll
          $lessFont
        />
        {foldersData &&
          foldersData.map((item, index) => {
            return (
              <SCheckBoxWithSubTitle
                $lessPadding
                $withPhoto
                selected={stagedSelectedItems.has(item.id)}
                title={item.name}
                onToggleCheck={handleSelect}
                key={`${item.id}-${index}`}
                valueId={item.id}
                checkBoxType={'box'}
                withoutSeparator
                withPhoto={true}
                PlaceHolder={Folder}
                clickAll
              />
            );
          })}
        {groupsData &&
          groupsData.map((item, index) => {
            return (
              <SCheckBoxWithSubTitle
                $lessPadding
                $withPhoto
                selected={stagedSelectedGroups.has(item.id)}
                title={item.name}
                onToggleCheck={handleSelectGroup}
                key={`${item.id}-${index}`}
                valueId={item.id}
                checkBoxType={'box'}
                withoutSeparator
                withPhoto={true}
                photoUrl={item.imageName}
                clickAll
              />
            );
          })}
      </SList>
      <SSeparator />
      <div className="buttonWrappers">
        <SButton
          $resetButton
          $valid={!true}
          disabled={!true}
          tx={'cancel'}
          onClick={handleCancel}
        />
        <SButton
          $valid={stagedSelectedItems.size + stagedSelectedGroups.size === 0}
          disabled={stagedSelectedItems.size + stagedSelectedGroups.size === 0}
          tx={mapOptionsTitle[type!]}
          onClick={handleFinish}
        />
      </div>
    </SDrawerModal>
  );
};

export const OperationListSelectModal = forwardRef(OperationModal) as <
  T extends OperationDataSelectableListType
>(
  props: IOperationListSelectModalProps<T> & { ref?: ForwardedRef<OperationListSelectModalRef<T>> }
) => ReturnType<typeof OperationModal>;
